
%btn {
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 22px;
  @include transition(background-color 0.14s ease-in-out);
  font-family: $roboto;
  padding: 0;

  // hovering on the btn
  &:hover, &:focus {
    text-decoration: none;
    outline: none;
  } // end hover

  // clicking on the button
  &:active {
    top: 1px; // adds a tiny hop to the click
    outline: none !important;
    box-shadow: inset 0 0px 0px rgba(0,0,0,0) !important;
  } // end active

} // end .button

.btn-plus {
	@extend %btn;
	position: relative;
	line-height: 46px;
	margin-top: 30px;

	.icon {
		display: inline-block;
		position: relative;
		z-index: 10;
		background: $orange2;
		color: $white;
		@include circle(60px);
		font-size: 28px;
		border: 4px solid $orange1;
		vertical-align: top;
		margin-right: 14px;
		text-align: center;

		&:before {
			position: relative;
			z-index: 11;
		}

		&:after {
			content: '';
			display: inline-block;
			position: absolute;
			z-index: 10;
			@include circle(60px);
			background: $orange1;
			left: -4px;
			top: -4px;
			transition: all 0.4s ease-in-out;
			transform: scale(0);
		}
	}

	&:hover, &:focus {
		color: $orange2;

		.icon:after {
			transition: all 0.4s ease-in-out;
			transform: scale(1);
		}

		span {
			&:after {
				bottom: -4px;
				@include transition(all .4s ease-in-out);
				border-color: $orange1;
			}
		}
	}

	&.plus-white {
		.icon {
			background: $white;
			color: $orange2;
		}

		&:hover, &:focus {
			.icon {
				color: $white;
			}
		}

		span {
			&:after {
				border-color: $white;
			}
		}
	}

	span {
		position: relative;
		padding-top: 3px;

		&:after {
			content: '';
			border-bottom: 3px solid $orange2;
			display: block;
			position: absolute;
			bottom: -5px;
			width: 100%;
			left: 0;
			@include transition(all .4s ease-in-out);
		}
	}
}

.btn-white {
	@extend %btn;
	position: relative;
	line-height: 46px;
	border: 3px solid $orange2;
	background: $white;
	@include rounded(12px);
	padding: 10px 50px 20px;
	font-size: 30px;
	@include transition(all .4s ease-in-out);

	span {
		@include transition(all .4s ease-in-out);
		&:after {
		    display: block;
		    content:'';
		    width: 72%;
		    background: $orange2;
		    position: absolute;
		    bottom: 17px;
		    border-bottom: 3px solid $orange2;
		    @include transition(all .4s ease-in-out);
		}
	}

	&:hover {
		border-color: $orange3;
		@include transition(all .4s ease-in-out);
		span {
			color: $orange3;
			@include transition(all .4s ease-in-out);
		}
		span:after, &:active span:after {
			bottom: 19px;
			border-color: $orange3;
		}
	}

	.page-id-9 & {
		border-color: #808e49;
		color: #808e49;

		span {
			@include transition(all .4s ease-in-out);
			&:after {
			    display: block;
			    content:'';
			    width: 72%;
			    background: #8aaa71;
			    position: absolute;
			    bottom: 17px;
			    border-bottom: 3px solid #8aaa71;
			    @include transition(all .4s ease-in-out);
			}
		}

		&:hover {
			border-color: #68723d;
			@include transition(all .4s ease-in-out);
			span {
				color: #68723d;
				@include transition(all .4s ease-in-out);
			}
			span:after, &:active span:after {
				bottom: 19px;
				border-color: #68723d;
			}
		}
	}

	.page-id-1052 & {
		span:after {
		    width: 61%;
		}
	}
}

.btn-orange {
	@extend %btn;
	position: relative;
	line-height: 26px;
	border: 3px solid $orange1;
	background: $orange2;
	@include rounded(12px);
	padding: 10px 50px 20px;
	@include transition(all .4s ease-in-out);
	color: $white;
	font-size: 22px;

	span {
		@include transition(all .4s ease-in-out);
		&:after {
		    display: block;
		    content:'';
		    width: 59%;
		    background: $white;
		    position: absolute;
		    bottom: 17px;
		    border-bottom: 3px solid $white;
		    @include transition(all .4s ease-in-out);
		}
	}

	&:hover {
		border-color: $orange2;
		background: $orange3;
		color: $white;
		@include transition(all .4s ease-in-out);
		span {
			color: $white;
			@include transition(all .4s ease-in-out);
		}
		span:after, &:active span:after {
			bottom: 19px;
			border-color: $white;
		}
	}
}

.btn-sq-orange, .acf-form-submit input {
	font-family: $roboto;
	width: auto;
	background: $orange2;
	color: $white;
	font-weight: 700;
	float: right;
	border: 0;
	box-shadow: none;
	padding: 6px 36px;
	font-size: 22px;
	margin-left: 15px;
	@include transition(all 0.15s ease);

	&:hover, &:focus {
		background: $orange3;
		@include transition(all 0.15s ease);
		color: $white;
		text-decoration: none;
	}
}

.btn-green {
	@extend .btn-white;
	@include rounded(8px);
	border-color: #588063;
	color: #588063;
	font-size: 19px;
	padding: 8px 30px;
	line-height: 40px;

	&:hover, &:focus {
		color: darken(#588063,10%);
		border-color: darken(#588063,10%);
	}
}

.btn-dark-green {
	@extend .btn-white;
	@include rounded(8px);
	background: #638050;
	border-color: $white;
	color: $white;
	font-size: 19px;
	padding: 8px 30px;
	line-height: 40px;

	&:hover, &:focus {
		color: darken(#588063,10%);
		border-color: darken(#588063,10%);
	}

	span {
		@include transition(all .4s ease-in-out);
		&:after {
		    display: block;
		    content:'';
		    width: 68%;
		    background: $white;
		    position: absolute;
		    bottom: 9px;
		    border-bottom: 3px solid $white;
		    @include transition(all .4s ease-in-out);
		}
	}

	&:hover {
		border-color: $white;
		@include transition(all .4s ease-in-out);
		span {
			color: $white;
			@include transition(all .4s ease-in-out);
		}
		span:after, &:active span:after {
			bottom: 12px;
			border-color: $white;
		}
	}
}

.btn-wrap {
	display: block;
	margin-top: 45px;
	text-align: center;
}


@media (max-width: $screen-sm-min) {
	.btn-plus {
		font-size: 20px;
		.icon {
		    width: 50px;
		    height: 50px;
		    line-height: 41px;
		    border-radius: 25px;
		    font-size: 23px;

		    &:after {
				@include circle(50px);
			}
		}
	}
}

@media (max-width: 800px) {
	.btn-plus {
		margin-top: 20px;
	}

	.btn-white {
		padding-left: 20px;
		padding-right: 20px;

		span {
			font-size: 21px;

			&:after {
			    width: 82%;
			    bottom: 23px;
			}
		}
	}
}

@media (max-width: 480px) {
	.btn-plus {
		font-size: 18px;
		line-height: 38px;
		.icon {
		    width: 40px;
		    height: 40px;
		    line-height: 34px;
		    border-radius: 20px;
		    font-size: 20px;

		    &:after {
				@include circle(40px);
			}
		}
	}

	.page-id-9 .btn-white {

		span {
			&:after {
			    width: 80%;
			}
		}
	}
}

