
/*********************
PAGE : HOME
*********************/

.home {
	font-size: 18px;
	line-height: 170%;
	font-weight: 300;

	.wrap.container {
		max-width: 820px;
	}

	.carousel .wrap.container {
		max-width: 900px;
	}

	.intro {
		font-size: 22px;
	}
}

@media (max-width: $screen-lg-min) {
	.home {
		.wrap.container {
	    	max-width: 710px;
		}

		.carousel .wrap.container {
			max-width: 780px;
		}
	}
}

@media (max-width: 800px) {
	.home {
		font-size: 16px;
	}
}

@media (max-width: 480px) {
	.home {
		.intro {
	    	font-size: 16px;
		}
	}
}

/* home :: hero */

#home-video {
	display: flex;
	height: 100%;
	min-height: 100vh;
	padding-top: 20%;
	position: relative;
	z-index

	video {
		z-index: 9990;
		/* position: fixed;
		width: 100%;
		z-index: -1; */
	}

	.scroll-dwn {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		top: calc(100% - 100px);
		width: 85px;
	}
}

.fullscreen-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
    background: url('../images/f2m-intro.jpg') left center / cover no-repeat;
}

.fullscreen-bg__video {
	position: absolute;
	top: 50%;
	left: 50%;
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
	#home-video {
		padding-top: 30%;
	}

	.home .intro {
	    font-size: 20px;
	}
}

@media (max-width: 800px) {

	.home .intro {
	    font-size: 17px;
	}

	#home-video {
		height: 500px;
		padding-top: 20%;
	}
}

@media (max-width: 767px) {
	.fullscreen-bg {

    }

    .fullscreen-bg__video {
        display: none;
    }
}

@media (max-width: 600px) {

    #home-video .scroll-dwn {
	    width: 55px;
	}
}

@media (max-width: 480px) {
	#home-video {
		padding-top: 100px;
	}

	.home {
		.intro {
	    	font-size: 16px;
		}
	}
}

/* home :: statement */

#home-statement {
	padding-bottom: 0;
	line-height: 170%;

	&:after {
		content: '';
		background: url('../images/bg-ltgray-sponge-border.jpg') center top no-repeat;
		background-size: 100% 101px;
		position: relative;
		display: block;
		height: 101px;
	}
}

@media (max-width: 1695px) {
    #home-statement {
		&:after {
			content: '';
			background: url('../images/bg-ltgray-sponge-border.jpg') center top no-repeat;
			background-size: auto auto;
			position: relative;
			display: block;
			height: 101px;
		}
	}
}

@media (max-width: $screen-lg-min) {
	#home-statement p:last-of-type {
		margin-bottom: 0;
	}
}

/* home :: our vision */

#home-vision {
	padding-bottom: 0;

	h2:after {
		display: block;
		content:'';
		background: url('../images/bg-gray-hashes.png') repeat-x;
		height: 8px;
		margin-top: 4px;
	}
}

/* home :: our program */

#home-program {
	background: $white url('../images/bg-home-our-program.jpg') 25% top no-repeat;
	background-size: 100% 760px;
	padding-top: 650px;

	.section-header {
		margin-bottom: 130px;
	}

	figure {
		font-size: 16px;
		margin-bottom: 30px;

		img {
			margin: 0 20px 0 0;
			width: 100%;
		}
	}
}

@media (max-width: 1695px) {
    #home-program {
		background-size: 1713px 794px;
	}
}


@media (max-width: $screen-lg-min) {
	#home-program {
		margin-top: -100px;
		background-position: 30% top;
		padding-top: 639px;
		padding-bottom: 0;
	}
}

@media (max-width: 800px) {
	#home-program {
		figure {
			font-size: 14px;
		}
		figcaption {
			line-height: 120%;
			margin-top: 5px;
		}
	}
}

@media (max-width: 480px) {
	#home-program {
		margin-top: -90px;
		background-size: auto 560px;
		padding-top: 474px;

		.section-header {
			margin-bottom: 80px;
		}
		figure {
		    font-size: 14px;
		    text-align: center;

		    img {
		    	margin: 0 auto;
		    	width: 75%;
		    }
		}

		figcaption {
			font-size: 12px;
		}
	}
}


/* home :: our members */

#home-members {
	background: url('../images/bg-home-our-members.jpg') left top no-repeat;
	background-size: cover;
	padding: 230px 0 125px;
	overflow: hidden;

	.content {

	}
}

@media (max-width: 1695px) {
    #home-members {
		background-size: cover;
		background-position: left top;
	}
}

@media (max-width: $screen-lg-min) {
	#home-members {
		.wrap.container {
			padding-left: 180px;
		}
	}
}

@media (max-width: 974px) {
	#home-members {
		.wrap.container {
			padding-left: 32%;
		}
	}
}

@media (max-width: 850px) {
	#home-members {
		background-size: 1200px auto;
		background-position: -130px;
		margin-top: 20px;

		.wrap.container {
			padding-left: 20%;
		}
	}
}

@media (max-width: 480px) {
	#home-members {
		background-position: -60px 0;
		padding: 0;
		font-size: 13px;

		.section-header {
			margin-bottom: 10px;
		}

		.wrap.container {
		    padding-left: 19%;
		    padding-top: 80px;
		    padding-bottom: 30px;
		}
	}
}

@media (max-width: 320px) {
	#home-members {
		padding: 0;
		font-size: 13px;

		.section-header {
			margin-bottom: 10px;
		}

		.wrap.container {
			margin: 120px auto 50px;
		    padding: 25px 15px;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#151413+0,151413+100&0.6+0,0.6+100 */
			background: -moz-linear-gradient(top,  rgba(21,20,19,0.6) 0%, rgba(21,20,19,0.6) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(21,20,19,0.6) 0%,rgba(21,20,19,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(21,20,19,0.6) 0%,rgba(21,20,19,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99151413', endColorstr='#99151413',GradientType=0 ); /* IE6-9 */
		}
	}
}

/* home :: member spotlight */

#home-member-spotlight {
	border-top: 8px solid $white;
	padding-bottom: 0;


	.content {
		font-size: 16px;
		line-height: 160%;
	}

	h2:after {
		display: block;
		content:'';
		background: url('../images/bg-black-hashes.png') repeat-x;
		height: 8px;
		margin-top: 4px;
	}

	&:after {
		content: '';
		background: url('../images/bg-ltgray-sponge-border-02.jpg') center top no-repeat;
		background-size: 100% 101px;
		position: relative;
		display: block;
		height: 101px;
	}
}

#home-member-carousel {
	padding-bottom: 75px;
	width: 91%;
	margin-left: auto;
	margin-right: auto;

	.content {
		padding: 4px 0 0 50px;
	}

	p:last-of-type {
		margin-bottom: 0;
	}

	.home-member-img {
		padding: 10px !important;
	}

	img {
		border: 12px solid $white;
		-webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
		box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
	}
}

@media (max-width: $screen-lg-min) {
	#home-member-spotlight .content {
	    font-size: 15px;
	}
}

@media (max-width: 800px) {
	#home-member-carousel .content {
	    font-size: 13px;
	    padding-left: 20px;
	    padding-right: 0px;
	}

	#home-member-carousel {
	    padding-bottom: 25px;
	}
}

@media (max-width: 480px) {
	#home-member-carousel {

		.home-member-img {
		    padding: 10px 6px !important;
		}

		img {
			display: block;
			border: solid #fff 2px;
			width: 100%;
			box-shadow: 0 0 3px 1px rgba(0,0,0,.2);
			margin: 3px 0 15px 3px;
		}

		.col-xs-5 {
			display: block;
			margin-right: 5px;
			margin-bottom: 0;
			width: 35%;
			padding: 0 20px 0 0 !important;
			float: left;
		}

		.col-xs-7 {
			float: none;
			width: auto;
			margin-left: calc(35% + 5px);
		}

		.content {
			padding-left: 0;
		}

		h5 {
			margin-bottom: 8px;
		}
	}
}

@media (max-width: 320px) {
	#home-member-carousel {

		.col-xs-5 {
			margin: 0;
			margin-bottom: 0;
		}
	}
}

/* home :: tools & resources */

#home-tools-resources {
	background: $white url('../images/bg-home-tools-resources.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 150px;
	overflow: hidden;

	img {
		position: relative;
		width: 250%;
		z-index: 10;
	}

	.col-md-3:first-of-type img {
		margin-left: -150%;
	}

	.col-md-9 {
		padding-top: 50px;
	}

	.row:last-child {
		margin-top: 50px;
	}

	.second-row {

	}

	.col-md-3 {
		position: relative;

		span {
			position: absolute;
			background-size: contain;
			z-index: 11;
			display: block;
			width: 100%;
			height: 100%;
			top: 32%;
			left: -50%;

			&.icon-fieldprint {
				background: url('../images/icon-home-fieldprint.png') no-repeat;
			}

			&.icon-national-indicator {
				background: url('../images/icon-home-national-indicators.png') no-repeat;
				background-size: 100% auto;
				left: 78%;
				width: 77%;
				top: 28%;
			}

			&.icon-partnering-impact {
				background: url('../images/icon-home-partnering-impact.png') no-repeat;
			}
		}
	}

	.section-header {
		margin-bottom: 110px;
	}
}

@media (max-width: 1695px) {
    #home-tools-resources {
		background-size: 100% auto;
		background-position: center 50px;
	}
}

@media (max-width: $screen-md-min) {
	#home-tools-resources {

		img {
			width: 100%;
		}

		.col-md-3:first-of-type img {
			margin-left: -10%;
		}

		.col-md-9 {
			padding-top: 20px;
		}

		.row:last-child {
			margin-top: 50px;
		}

		.col-md-3 {

			span {
				width: 40%;
				height: 40%;
				top: 32%;
				left: 0;
				margin-left: 35px;

				&.icon-fieldprint {
				}

				&.icon-national-indicator {
					left: 18%;
					width: 77%;
					top: 30%;
				}
			}
		}
	}
}

@media (max-width: $screen-md-min) {
	#home-tools-resources {

		img {
			width: 100%;
			max-width: 300px;
		}

		.col-md-3 {

			span {
				margin-left: 50px;

				&.icon-national-indicator {
					left: 13%;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	#home-tools-resources {

		.second-row {
			display: -webkit-flex;
		   display: flex;
		   -webkit-flex-direction: column;
		   flex-direction: column;

			> div:first-child {
				order: 2;
			}

			> div:last-child {
				order: 1;
			}
		}
	}
}

@media (max-width: 480px) {
	#home-tools-resources {
		padding-top: 185px;

		.section-header {
	    	margin-bottom: 60px;
		}

		.col-md-3 {

			span {
				margin-left: 70px;
			}

			&.icon-national-indicator {
				left: 13%;
			}
		}
	}
}

@media (max-width: 400px) {
	#home-tools-resources {
		padding-top: 165px;
	}

	.col-md-3 {
		span {
			margin-left: 75px;
		}
	}
}

/* home :: farmer's spotlight */

#farmer-spotlight {
	background: url('../images/bg-home-farmer-spotlight.jpg') center top no-repeat;
	background-size: cover;
	padding: 125px 0;

	.page-id-87  & {
		margin-bottom: 75px;
	}

	h2:after {
		display: block;
		content:'';
		background: url('../images/bg-white-hashes.png') repeat-x;
		height: 8px;
		margin-top: 4px;
	}
}

#home-farmer-carousel, #member-carousel, #farmer-carousel, #case-study-carousel {
	-webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.28);
	-moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.28);
	box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.28);
	 width: 91%;
	 margin-left: auto;
	 margin-right: auto;
	 height: auto;
	 background: $white;

	.owl-item {
		background: $white;
		height: 100%;
		display: block;
		padding: 22px;
		font-size: 16px;
		line-height: 160%;

		.btn-plus {
			margin-top: 20px;
		}
	}

	.content {
		padding: 25px 15px 0 35px !important;
	}
}

#home-farmer-carousel {
	.content {
		padding: 10px 15px 0 35px !important;
	}
}

#member-carousel, #case-study-carousel {
	margin-bottom: 40px;
}

@media (max-width: $screen-lg-min) {
	#home-farmer-carousel .owl-item, #farmer-carousel .owl-item {
		font-size: 16px;
	}
}

@media (max-width: 767px) {
    #home-farmer-carousel, #farmer-carousel {
		.content {
			padding: 25px 0 0 !important;
		}

		p {
			margin-bottom: 5px;
		}

		.owl-item {
      		font-size: 15px;
      		line-height: 150%;
  		}
	}
}

/* home :: partnering for impact */

#home-partner-impact {
	background: $white url('../images/bg-home-partner-impact-head.jpg') 0 145px no-repeat;
	background-size: 100% auto;
	padding: 200px 0;

	header {
		height: 112px;
		margin-bottom: 0;
	}

	img {
		position: relative;
		width: 130%;
		z-index: 10;
		margin-left: -55%;
	}

	.main-wrap {
		background: $white url('../images/bg-home-partner-impact.jpg') no-repeat;
		background-size: auto 100%;
		padding-top: 80px;
	}

	.col-md-4 {
		position: relative;

		span {
			position: absolute;
			background-size: contain;
			z-index: 11;
			display: block;
			width: 66%;
			height: 100%;
			top: 38%;
			left: -17%;

			&.icon-partnering-impact {
				background: url('../images/icon-home-partnering-impact.png') no-repeat;
			}
		}
	}
}

@media (max-width: 1695px) {
    #home-partner-impact {

	}
}

@media (max-width: 800px) {
	#home-partner-impact {
		padding: 125px 0 75px;

		img {
			margin-left: -25%;
			width: 115%;
		}

		.col-md-4 span {
		    top: 39%;
		    left: 9%;
		    width: 55%;
		}
	}
}

@media (max-width: 767px) {
	#home-partner-impact {

		.content {
			padding: 20px 15px;

			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+0,ffffff+100&0.6+0,0.6+100 */
			background: -moz-linear-gradient(top,  rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(255,255,255,0.6) 0%,rgba(255,255,255,0.6) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(255,255,255,0.6) 0%,rgba(255,255,255,0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99ffffff', endColorstr='#99ffffff',GradientType=0 ); /* IE6-9 */
		}

		.col-md-4 {
		    float: left;
		}

		.col-md-8 {
		    float: none;
		    width: auto;
		}
	}
}

/* home :: join us */
#join-us, #home-become-member {
	background: url('../images/bg-home-join-us.jpg') no-repeat;
	background-size: cover;
	text-align: center;
	font-weight: 300;
	padding: 100px 0;
	font-size: 20px;
	line-height: 135%;
	border-top: 6px solid $white;

	.col-lg-7 {
		text-align: left;
	}

	.home & {
		text-align: center;
		font-weight: 700;
		line-height: 220%;
		border-top: none;
	}

	.content {
		margin-bottom: 35px;
	}

	ul {
		padding-left: 20px;
		margin: 20px 0;
	}

	.btn-white {
		margin-top: 20px;
	}

	.left-col-img img {
		max-width: 276px;
		margin-top: 150px;
		margin-left: 0;
	}

	.page-id-9 & {
		background: url('../images/bg-home-join-us-green.jpg') no-repeat;
		background-size: cover;
	}
}

@media (max-width: 1695px) {
    .join-us {
		background-size: 100% auto;
		background-position: 0 0;
	}
}
@media (max-width: 991px) {
	.left-col-img img {
		width: 80%;
		max-width: 80%;
	}
}

@media (max-width: 800px) {
    .join-us {
		padding: 75px 0;
		font-size: 18px;
	}
}

@media (max-width: 480px) {
    .join-us {
    	font-size: 16px;
		padding: 50px 0;

		.col-xs-1 {
			display: none;
		}

		.btn-white span {
		    font-size: 18px;
		}

		.left-col-img img {
			display: none;
		}
	}
}

/*********************
PAGE : OUR MEMBERS
*********************/

#our-members-blk {
	background-image: url('../images/bg-our-members-explore-head.jpg');
}

.filter-bar {
	background-color: #dedede;
	padding: 10px 15px;
	margin: 25px 0;

	button:hover, button:focus {
		text-decoration: underline;
	}
}

#member-grid {
	button.btn-filter {
		font-family: $ssp;
		background: none;
		border: none;
		margin-right: 2px;
		font-size: 19px;
		padding: 0 5px;

		&:last-child {
			margin-right: 0;
		}

		&.is-checked {
			color: $orange2;
			text-decoration: underline;
		}
	}
}

.member-grid {
	margin-bottom: 60px;

	a img {
		filter: grayscale(100%); /* Standard CSS */
	    -webkit-filter: grayscale(100%); /* CSS for Webkit Browsers */
	    filter: url(/elements/grayscale.svg#desaturate); /* Firefox 4-34 */
	    filter: gray;  /* Internet Explorer IE6-9 */
	    -webkit-filter: grayscale(1); /* Old WebKit Browsers */
	    max-width: 200px;

	    &:hover {
	    	filter: grayscale(0%);
		    filter: none;
		    -webkit-filter: grayscale(0); /* Old WebKit */
	    }
	}

	&:last-child {
		margin-bottom: 100px;
	}
}

.gutter-sizer { width: 4%; }

.grid-sizer, .grid-item {
	width: 16%;
}

.grid-item {
	margin-bottom: 4%;

	img {
		width: 100%;
		max-width: 100%;
	}
}

.is-showing-items .grid-item {
  opacity: 1; /* reveal when ready */
}

@media (max-width: 1200px) {
	#member-grid {
		button.btn-filter {
			font-size: 16px;
		}
	}
}

@media (max-width: 860px) {
	#member-grid {
		button.btn-filter {
			font-size: 13px;
		}
	}
}

@media (max-width: 480px) {

	.btn-group {
		display: block;

		button {
			margin-right: 5px;
			font-size: 12px;
			padding: 0 11px;
		}
	}

	.gutter-sizer { width: 4%; }

	.grid-sizer, .grid-item {
		width: 48%;
	}
	.member-grid {
		position: relative;
		.grid-item {
			float: left;
			margin-bottom: 4%;
			text-align: center;

			img {
				width: 100%;
				vertical-align: top;
			}
		}
	}
}


/* PAGE LAYOUT STYLES */

.intro {
	font-size: 24px;
	line-height: 150%;
}

.layout {
	padding: 65px 0;
	font-size: 18px;
	line-height: 165%;

	p {
		margin: 25px 0;
	}

	ul {
		padding-left: 20px;
		overflow: hidden;

		.site-map & {
			ul {
				margin-top: 6px;
			}
		}

		li {
			margin-bottom: 6px;
		}
	}

	&.three-col-mag {
		position: relative;
		padding-top: 0;
		margin-top: -50px;

		img {
			width: 100%;
		}
	}
}

.page-content img {
	max-width: 100%;
}

.left-col-img {
	position: relative;

	img {
		display: block;
		margin-left: -30%;
		max-width: 335px;
		position: absolute;

		#join-us & {
			margin-left: -5%;
		}

		#project-directory-intro & {
			margin-left: -60%;
			max-width: 200%;
			width: 200%;
			top: -45px;
		}
	}
}

.layout-wide-top-bg {
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center top;
	margin-top: -75px;

	.page-id-6 & {
		margin-top: 0;
	}
}

.layout-two-col-text {
	position: relative;

	// MEMBER GRID
	.page-id-6 & {
		padding-top: 60px;
	}

	.wrap {
		padding-left: 176px;
		padding-right: 176px;
	}

	.col-lg-6 {
		padding-top: 5px;
		padding-bottom: 25px;
		display: inline-block;

		&:first-child {
			padding-right: 50px;
			border-right: 1px dashed $white;
		}

		&:last-child {
		padding-left: 50px;
		}
	}
}

@media (max-width: 1695px) {
    .layout-wide-top-bg {
		background-size: 1700px auto;
		padding: 220px 0 75px;

		// MEMBER GRID
		.page-id-6 & {
			padding-top: 60px;
		}

		.wrap {
			padding-left: 210px;
			padding-right: 210px;
		}
		&.wide-content .wrap {
			padding-left: 100px;
			padding-right: 100px;
		}
	}

	.simple-content {
		.wrap {
			padding-left: 165px;
			padding-right: 165px;

			.single-cpt-projects & {
				padding-left: 145px;
				padding-right: 145px;
			}
		}
	}
}

@media (max-width: $screen-lg-min) {
	.left-col-img {

		img {
			max-width: 100%;
		}
	}

	.layout-left-col-img {
		.intro {
			margin-top: 0;
		}
	}

	.layout-wide-top-bg {

		.wrap {
			padding-left: 138px;
			padding-right: 0px;
		}
	}

	.simple-content {
		.wrap {
			padding-left: 65px;
			padding-right: 65px;

			.single-cpt-projects &, #news-press-press &, #event-list-past &, #event-list-upcoming & {
				padding-left: 65px;
				padding-right: 65px;
			}
		}
	}

	.intro, p.intro {
		font-size: 20px;
		margin-top: 20px;
	}

	.layout {
		font-size: 16px;
	}

	.layout:first-of-type {
		padding-top: 45px;
	}
}

@media (max-width: 1200px) {
	.layout-two-col-text {

		.wrap {
			padding-left: 50px;
			padding-right: 50px;
		}
	}

	.layout-wide-top-bg {
		&.wide-content .wrap {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

@media (max-width: 991px) {

	.left-col-img {
		img {
			#join-us & {
				margin-left: -5%;
			}
		}
	}
	.layout-two-col-text {

		.wrap {
			padding-left: 15px;
			padding-right: 15px;
		}

		.col-lg-6 {

			&:first-child {
				border-right: none;
				padding-right: 15px;
			}
			&:last-child {
				padding-left: 15px;
			}
		}
	}
}

@media (max-width: 767px) {
	.intro, p.intro {
		font-size: 18px;
	}

	.layout {
		font-size: 14px;
	}

	.simple-content {
		.wrap {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}
/*
@media (max-width: 600px) {
	.left-col-img {

		img {
			margin-left: 0;
			max-width: 100%;
			position: relative;
			vertical-align: top;

			#project-directory-intro & {
				margin-left: 0;
				max-width: 100%;
				width: 100%;
				top: 0;
			}
		}
	}
} */

@media (max-width: 480px) {
	.layout-wide-top-bg {
		padding-bottom: 45px;
		margin-top: -125px;
		.wrap {
			padding-left: 64px;
		}
	}

	.simple-content {
		.wrap {
			padding-left: 10px;
			padding-right: 10px;

			.single-cpt-projects & {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	.layout-two-col-text {
		.btn-plus span {
		    font-size: 12px;
		}
	}
}

/* ALLIANCE */

#alliance-long-left {
	background-image: url('../images/bg-alliance-our-vision-head.jpg');
}

#join-us {
	margin-top: 6px;

	.content {
		font-size: 20px;
		font-weight: 700;
		max-width: 750px;
		margin: 0 auto 35px;
		line-height: 150%;
	}

	.btn-white {
		margin-top: 30px;
	}
}

.fifty-million {
	margin-right: -150px;
	margin-left: 50px;
}

.page-id-5, .page-id-716 {
	.bg-wrap {
		background: url(../images/bg-alliance-crops-vert.jpg) no-repeat;
		background-size: auto 100%;
		background-position: -127px 400px;
	}

	.layout-two-col-text {
		background: $teal1;
		margin-top: 124px;
		color: $white;


		&:before {
			content: '';
			background: url('../images/bg-teal-sponge-border.jpg') center top no-repeat;
			background-size: 100% 104px;
			position: absolute;
			display: block;
			height: 104px;
			top: -104px;
			width: 100%;
			left: 0;
		}
	}
}

@media (max-width: $screen-lg-min) {
	/* ALLIANCE */
	.page-id-5, .page-id-716 {
		.bg-wrap {
			background-size: 338px auto;
			background-position: -166px 321px;
		}
	}

	.fifty-million {
		margin-right: 0;
		margin-left: 50px;
		width: 150px;
		float: right;
	}
}

@media (max-width: 480px) {

	/* ALLIANCE */
	.page-id-5 .bg-wrap, .page-id-716 .bg-wrap {
	    background-size: 270px auto;
	}

	.fifty-million {
		width: 80px;
		float: right;
	}
}

@media (max-width: 320px) {

	.fifty-million {
		width: 60px;
		float: right;
	}
}

@media (min-width: 1696px) {
	.wrap {
		#fprr-measurement & {
			padding-left: 55px;
		}
	}
}

/* OUR MEMBERS */

#member-rotator {
	&.bg-teal {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 104px;
			background: url('../images/bg-teal-sponge-border.jpg') no-repeat center top;
			background-size: 100% 104px;
			position: absolute;
			top: -104px;
			left: 0;
		}
	}
}

/* OUR PROGRAMS */

.island-left-col  {
	overflow: hidden;
	font-size: 18px;

	img {
		width: 98%;
	}

	.intro {
		padding-top: 40px;
	}
}

#our-program-benchmark {
	background: $white url('../images/bg-wrap-width-left-lean.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 183px;
}

#our-program-catalyzing {
	background: $white url('../images/bg-island-btm-right.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 124px;
}

#our-program-enabling {
	background: $white url('../images/bg-island-top-right.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 186px;
	padding-bottom: 0;
}

@media (max-width: 1695px) {
	#our-program-benchmark, #our-program-catalyzing, #our-program-enabling {
		background-size: 1700px 250px;
	}
}


.dotted-gray {
	background: url('../images/bg-gray-dots.jpg') repeat-x;
	background-size: 1140px 3px;
	padding-top: 60px;
	margin-top: 75px;

	#our-program-claims & {
		padding-top: 80px;
    	margin-top: 10px;
	}
}

.layout-two-col {
	padding-left: 150px;
	padding-right: 150px;

	.col {
		&:first-child {
			padding-right: 20px;
		}
		&:last-child {
			padding-left: 20px;
		}
	}

	.img-wrap {
		text-align: center;
	}

	a.img-wrap {
		display: block;
	}

	img {
		width: 70%;
		margin: 0 auto 25px;
		vertical-align: top;
	}

	.btn-plus {
		.icon {
			float: left;
		}
		span {
			font-size: 18px;
			line-height: 28px;
			padding-top: 7px;
			margin-left: 85px;
			display: block;
			margin-top: -9px;
		}
	}
}

#our-program-enabling-tri {
	padding-bottom: 0;
	img {
		max-width: 215px;
		width: 80%;
	}
}

#our-program-claims {
	padding-bottom: 0;

	img {
		max-width: 100%;
		margin-bottom: 16px;
	}
}

.bq-blk {
	margin-bottom: 100px;

	.row {
		border-top: 2px solid #cfcfcf;
		border-bottom: 2px solid #cfcfcf;
		padding: 0;
	}

	.page-id-15 & {
		margin-top: 0;
		padding-top: 50px;
		position: relative;

		.row {
			padding: 20px 0 5px;
		}

		.wrap {
			padding-left: 150px;
			padding-right: 150px;
		}
	}
}

#join-us-intro {
	margin-top: 30px;
	background: $white url('../images/bg-island-btm-right.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 104px;
	padding-bottom: 0;
	z-index: 10;

	.col-sm-8 {
		padding-top: 45px;
	}
}

#join-us-benefits {
	background: #fff url(../images/bg-big-low-left.jpg) 30% top no-repeat;
	background-size: 100% 760px;
	padding-top: 425px;
	margin-top: -300px;
	z-index: 8;
	font-size: 18px;

	.wrap {
		padding-left: 210px;
		padding-right: 210px;

		img {
			max-width: 206px;
		}
	}
}

@media (max-width: 1695px) {

	#join-us-intro {
		background-size: 1700px 250px;
	}

	#join-us-benefits {
		background-size: 1700px 719px;
	}
}

@media (max-width: 990px) {

	.bq-blk {
		.page-id-15 & {
			.wrap {
				padding-left: 60px;
				padding-right: 60px;
			}
		}
	}

	.layout-two-col {
		padding-left: 60px;
		padding-right: 60px;
	}

	#join-us-benefits {
		margin-top: -420px;
		.wrap {
			padding-left: 60px;
			padding-right: 60px;
		}
	}
}

@media (max-width: 767px) {

	.layout-two-col {
		padding-left: 25px;
		padding-right: 25px;

		.col-xs-12 {
			margin-bottom: 65px;
		}
	}

	#join-us-benefits {

		.wrap {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
}



.benefit-list {
	margin: 50px 0;
}

.benefit {
	text-align: center;
	margin-bottom: 25px;

	&:nth-child(2n+1) {
		padding-right: 15px;
	}

	&:nth-child(2n+2) {
		padding-left: 15px;
	}

	&:nth-child(2n+3) {
		clear: left;
	}
}

#member-rotator.bg-green-sponge {
	padding: 115px 0;
	background: url('../images/bg-green-sponge.jpg') center top no-repeat;
	background-size: cover;
}

#join-us-member-types {
	background: $white url('../images/bg-island-top-right.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 124px;
	padding-bottom: 0;
	font-size: 18px;
}

#join-us-member-rows {
	.col-lg-7 {
		border-bottom: 3px solid #cfcfcf;
		padding-bottom: 35px;
		margin-bottom: 30px;
	}

	.row:last-child .col-lg-7 {
		border-bottom: 0;
	}
}

.with-form {
	font-size: 18px;
}

.narrow {
	max-width: 880px;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}

@media (max-width: 1695px) {
	#join-us-member-types {
		background-size: 1700 auto;
	}
}

@media (max-width: $screen-md-min) {
	#join-us-member-types {
		padding-top: 131px;
	}
}


/* GET IN TOUCH */

.layout.get-in-touch {
	padding-top: 0;
	margin-top: -25px;
	text-align: left;

	img {
		width: 100%;
		border: 4px solid #bdbdbd;
	}

	.col-lg-7 {
		padding-left: 50px;
	}
}

.get-in-touch-form {
	background: #d4d4d4;
	border-top: #bdbdbd 4px solid;
	padding: 40px 60px;
}

.page-id-9 .get-in-touch .container {
	max-width: 1000px;
}

.simple-content.inquiries {
	padding-top: 0;

	a {
		margin-bottom: 15px;
		display: inline-block;
		color: $black;
		font-weight: 300;
		line-height: 42px;

		i {
			color: $white;
			display: inline-block;
			width: 40px;
			background: $orange2;
			height: 40px;
			border-radius: 100%;
			text-align: center;
			vertical-align: bottom;
			line-height: 42px;
			font-size: 24px;
			@include transition(all 0.15s ease);
			margin-right: 8px;
		}
	}
}

@media (max-width: 767px) {
	.layout.get-in-touch {

		.col-lg-7 {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

/* GOVERNANCE */

.sector-list {
	margin: 35px 0 30px;

	img {
		width: 100%;
		max-width: 128px;
	}
}

#gov-sectors {

	.gov-sector {
		text-align: center;
		margin-right: 4%;

		&:last-child {
			margin-right: 0;
		}
	}
}

.tabled {
	border-top: 1px solid #666;
	border-bottom: 1px solid #666;
	padding: 5px 0;
	margin-top: 45px;
}

@media (max-width: 990px) {
	#gov-sectors {

		.gov-sector {
			margin-right: 0;
			margin-bottom: 3%;

			&:nth-child(3n+3) {
				margin-right: 0;
			}

			&:nth-child(3n+4) {
				clear: left;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

@media (max-width: 767px) {
	#gov-sectors {

		.gov-sector {
			margin-right: 0;
			margin-bottom: 3%;

			&:nth-child(3n+3) {
				margin-right: 0;
			}

			&:nth-child(3n+4) {
				clear: none;
			}

			&:nth-child(2n+3) {
				clear: left;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

/* OUR HISTORY */

.timeline {
	background: url('../images/bg-our-history-grass.jpg') no-repeat;
	background-position: center bottom;
	background-size: 100% 282px;
	padding-bottom: 261px;
	padding-top: 50px;
	position: relative;
	z-index: 10;
	margin-top: 150px;
}

.timeline ul {
	padding: 50px 0;
	z-index: 10;
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 107px;
		height: 51px;
		background: url('../images/icon-timeline-top.png') no-repeat;
		background-size: 107px 51px;
		position: absolute;

		left: 50%;
		margin-left: -53.5px;
		top: 10px;
	}
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 3px;
  margin: -40px auto;
  padding-top: 50px;
  background: #b6c287;
  min-height: 200px;

  &:last-child {
  	min-height: 230px;
  }

  &:first-child {
  	margin-top: 0;
  }
  //tree
}

.timeline ul li time {
	content: '';
	position: absolute;
	left: 50%;
	top: 40px;
	transform: translateX(-50%);
	width: 130px;
	height: 44px;
	background: inherit;
	text-align: center;
	color: $white;
	line-height: 44px;
	font-size: 22px;

	i {
		content: '';
		position: absolute;
		bottom: 13px;
		width: 0;
		height: 0;
		border-style: solid;
	}

	span {
		font-weight: bold;
	}
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 300px;
  font-size: 16px;
}

.timeline ul li:nth-child(odd) div {
  left: 100px;
}

.timeline ul li:nth-child(odd) time i {
  right: -15px;
  border-width: 8px 0 8px 16px;
}

.timeline ul li:nth-child(even) div {
  left: -399px;
  text-align: right;
}

.timeline ul li:nth-child(even) time i {
  left: -15px;
  border-width: 8px 16px 8px 0;
}


/* EFFECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul li time {
  transition: background .5s ease-in-out;
}

.timeline ul li.in-view::after {
  background: #F45B69;
}

.timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all .5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

#barn {
	max-width: 121.5px;
	width: 10%;
	height: auto;
	position: absolute;
	bottom: 285px;
	left: 25%;
}

#tractor {
	max-width: 236.5px;
	width: 20%;
	height: auto;
	position: absolute;
	bottom: 250px;
	left: 56%;
}

#sun-cloud {
	position: absolute;
	top: -100px;
	left: 5%;
	width: 35%;
	max-width: 541px;
	height: auto;
}

#sky-elements {
	display: block;
	position: absolute;
	z-index: 9;
	top: 0;
	left: 0;
	width: 100%;

	img {
		position: absolute;
		display: block;
		height: auto;
	}
}

#cloud-01 {
	width: 18%;
	max-width: 309px;
	right: 22%;
	top: -100px;
}

#little-cloud {
	width: 14%;
	max-width: 257px;
	left: 20%;
	top: 1680px;
}

#big-cloud-01 {
	max-width: 455px;
	top: 1800px;
	left: 2%;
	width: 25%;
}

#birds {
	max-width: 206px;
	top: 1800px;
	right: 10%;
}

#cloud-birds {
	top: 880px;
	left: 12%;
	max-width: 305px;
	width: 20%;
}

#med-cloud {
	top: 2800px;
	left: 10%;
	max-width: 359px;
	width: 22%;
}

#big-clouds-01 {
	top: 1200px;
	right: 10%;
	max-width: 579px;
	width: 30%;
}

#big-clouds-02 {
	top: 2990px;
	right: 1%;
	max-width: 615px;
	width: 38%;
}

@media screen and (max-width: 900px) {

	.timeline ul {
		overflow: hidden;
	}

  .timeline ul li div {
    width: 200px;
    font-size: 14px;
    h5 {
    	font-size: 18px;
    }
  }
  .timeline ul li:nth-child(even) div {
    left: -299px;
    /*250+45-6*/
  }
}

@media screen and (max-width: 640px) {

	#big-clouds-02 {
		top: 2300px;
	}

	#med-cloud {
		top: 2210px;
	}

	#sun-cloud {
		position: absolute;
		left: auto;
		right: 2%;
	}

	#cloud-01 {
		right: 0;
		left: 50%;
	}

	#tractor {
		width: 25%;
		bottom: 260px;
		left: 68%;
	}

	#barn {
		width: 20%;
	}

	.timeline {
		margin-top: 110px;
		padding-top: 0;
	}

  .timeline ul {
  	overflow: hidden;

  	&:before {
  		left: 81.5px;
  	}

  	li {
    	margin-left: 80px;

    	time i {
	    	display: none;
	    }
	}
  }
  .timeline ul li div {
    width: calc(100vw - 201px);
    left: 90px;
  }
  .timeline ul li:nth-child(even) div {
    left: 90px;
    text-align: left;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #F45B69 transparent transparent;
  }
}

/* FIELDPRINT PLATFORM & FPRR & FORMS */

/* OUR MEMBERS */

#case-study-rotator {
	&.bg-blue-slate {
		position: relative;
		margin-top: 100px;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 95px;
			background: url('../images/bg-blue-gray-sponge-border.jpg') no-repeat center top;
			background-size: 100% 95px;
			position: absolute;
			top: -95px;
			left: 0;
		}
	}
}

#benefits, #fprr, #fprr-measurement, #fieldprint-projects {
	background: $white url('../images/bg-island-top-right.jpg') center 0px no-repeat;
	background-size: 100% auto;
	padding-top: 112px;
	padding-bottom: 0;

	img {
		width: 100%;
		max-width: 128px;
	}

	.left-icon-list {
		margin-top: 50px;
		font-size: 16px;
		margin-bottom: 30px;
	}

	.row {
		margin-bottom: 40px;

		ul {
			padding-left: 18px;
		}
	}
}

#fprr, #fprr-measurement {
	padding-top: 90px;
	margin-top: 90px;

	ul {
		margin-top: 40px;

		li {
			margin-bottom: 12px;
		}
	}

	.row {
		margin-bottom: 50px;

		&:last-of-type {
			margin-bottom: 0px;
		}
	}

	.row-content a {
		display: inline-block;
		margin-top: 20px;
		text-decoration: underline;
	}
}

#fprr-measurement {
	margin-bottom: 50px;
}

.img-caption {
	margin-top: 20px;
	font-size: 18px;
}

#how-does-it-work {
	background: $white url('../images/bg-island-top-left.jpg') center 0px no-repeat;
	background-size: 100% auto;
	padding-top: 112px;

	img {
		width: 100%;
	}
}

#fieldprint-cta {
	padding: 115px 0;
	background: url('../images/bg-green-sponge-top.jpg') center top no-repeat;
	background-size: cover;

	.btn-green {
		margin: 25px auto;
	}

	.col {
		&:first-child {
			padding-right: 50px;
		}
		&:last-child {
			padding-left: 50px;
		}
	}
}

@media screen and (max-width: 1695px) {
	#benefits, #fieldprint-projects {
		background: $white url('../images/bg-island-top-right.jpg') center 0px no-repeat;
		background-size: 1700px 250px;
	}
}

@media screen and (max-width: 767px) {

	#how-does-it-work {
		.col-sm-2 {
			display: none !important;
		}
	}

	#fprr, #fprr-measurement {
		padding-top: 100px;
	}
}

@media screen and (max-width: 480px) {

	#fprr, #fprr-measurement {
		padding-top: 110px;
	}
}

/* NEWS + PRESS */

#news-press-news, #news-press-press {
	background: $white url('../images/bg-island-even.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 110px;
	padding-bottom: 0;

	ul {
		list-style: none;
		margin: 25px 0 10px 0;
		padding-left: 0;
		border-bottom: 2px solid #989898;
		padding-bottom: 25px;

		li {
			margin-bottom: 25px;

			&:last-child {
			}
		}

		a {
			text-decoration: underline;
			margin-bottom: 2px;
		}
	}
}

#news-press-news {
	position: relative;
	z-index: 11;
	margin-top: 50px;
}

#news-press-press {
	z-index: 10;
	background: url('../images/bg-island-oblong.jpg') center 50px no-repeat;
	padding-top: 495px;
	margin-top: -90px;

	&.layout-wide-top-bg .wrap {
		padding-left: 165px;
		padding-right: 165px;
	}
}

@media (max-width: $screen-lg-min) {
	#news-press-press.layout-wide-top-bg .wrap {
		padding-left: 65px;
		padding-right: 65px;
	}
}

@media (max-width: 900px) {
	#news-press-press {
		margin-top: -250px;
	}
}

@media (max-width: 767px) {

	#news-press-press.layout-wide-top-bg .wrap {
		padding-left: 25px;
		padding-right: 25px;
	}
}

#twitter-blk {
	display: block;
	background: url('../images/bg-twitter.jpg') no-repeat;
	background-size: 100% auto;
	padding: 75px;
	margin: 50px 0;
	min-height: 535px;

	header {
		border-top: dashed $white 2px;
		border-bottom: dashed $white 2px;
		padding: 10px 0;
	}
}

#news-list {
	ul {
		list-style: none;
		margin: 25px 0 10px 0;
		padding-left: 0;
		padding-bottom: 25px;

		&.paged {
			border-bottom: 2px solid #989898;
		}

		li {
			margin-bottom: 25px;

			&.date {
				margin-top: 15px;
				font-size: 24px;
				text-decoration: underline;
			}
		}

		a {
			text-decoration: underline;
			margin-bottom: 2px;
		}
	}
}

/* EVENTS */

#event-list-upcoming, #event-list-past {
	background: $white url('../images/bg-island-even.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 103px;
	padding-bottom: 0;

	ul {
		list-style: none;
		margin: 25px 0 10px 0;
		padding-left: 0;

		li {
			margin-bottom: 25px;
			color: #8c8c8c;
		}

		a {
			text-decoration: underline;
			margin-bottom: 2px;
		}
	}
}

#event-list-upcoming {
	margin-top: 50px;
}

@media (max-width: 1695px) {
	#event-list-upcoming {
		background-size: 1700px auto;
	}
}

#event-list-past {
	background: $white url('../images/bg-island-top-right.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 156px;
	padding-bottom: 100px;

	ul {
		padding-left: 0;
		padding-bottom: 25px;
		border-bottom: 2px solid #989898;
	}
}

@media (max-width: 1695px) {
	#event-list-past {
		background-size: 1700px auto;
	}
}

#special-event {
	background: url('../images/bg-green-watercolor.jpg') center 50px no-repeat;
	background-size: cover;
	padding-top: 130px;
	padding-bottom: 100px;
}

.special-event-date {
	display: block;
	margin: 45px 0;
	height: 50px;
	line-height: 42px;
	text-align: center;
	font-family: $roboto;
	font-size: 24px;
	border-top: 2px dashed $white;
	border-bottom: 2px dashed $white;
}

.sidebar-email {
	padding: 20px 20px 10px;
}

.callout {
	color: #9bbdd1;
	font-style: italic;
	font-size: 21px;
	margin-top: 250px;

	p {
		margin-top: 0;
	}
}

.att {
	text-align: right;
	font-size: 18px;
	color: $black;
	line-height: 130%;
}

.news-pagination {
	a {
		display: inline;
	}
	.next-link {
		float: right;
	}

	.prev-link {
		display: inline;
	}
}

/* Twitter Customizations */

.tweets {
	.tweet-wrap {
		border: none;
		box-shadow: none;
		display: inline-block;
		width: 32%;
		margin-right: 1%;
		float: none;
		padding: 0;
		vertical-align: top;

		&:nth-child(4), &:nth-child(5), &:nth-child(6) {
			display: none;
		}
	}

	.tweet-content {
		background: none;
		border: none;
		color: $white;
		font-size: 15px;

		a {
			color: $white !important;
			font-size: 15px;
		}

		.aptf-timestamp {
			margin-bottom: 10px !important;
			display: none;

			a {
				text-decoration: underline !important;
				font-weight: 400 !important;
			}
		}
	}

	.aptf-tweet-box {
		color: $white;
	}
}

@media (max-width: 1695px) {
	#news-press-news, #news-press-press {
		background-size: 1700px auto;
	}
}

@media (max-width: $screen-md-min) {
	.tweets {

		.tweet-content {
			font-size: 14px;

			a {
				font-size: 14px;
			}
		}

		.aptf-tweet-box {
			color: $white;
		}
	}

	#twitter-blk {
	    padding: 45px;
	}
}

@media (max-width: 920px) {
	#twitter-blk {
	    padding: 30px 30px 20px;
	    min-height: 425px;

	    header {
		    padding: 8px 0;
		    margin-bottom: 20px;
		}
	}

	.tweets {
		.tweet-wrap {
			width: 30%;
			margin-right: 3%;
		}
	}
}


@media (max-width: 820px) {
	.tweets {

		.tweet-content {
			font-size: 13px;

			a {
				font-size: 13px;
			}
		}
	}
}

@media (max-width: 767px) {
	.callout {
		margin-top: 40px;
	}
}

@media (max-width: 740px) {
	#twitter-blk {
		background: none;
		background: #00b7f3;
		@include rounded(14px);

		header {
			padding: 12px 0;
			margin-bottom: 45px;
		}
	}

	.tweets {
		.tweet-wrap {
			display: block;
			width: 100%;
			margin-right: 0;
			font-size: 18px;
		}

		.tweet-content {
			font-size: 18px;

			a {
				font-size: 18px;
			}
		}
	}
}

/* SITEMAP */

.site-map .layout {
	ul {
		ul {
			font-size: 14px;

			li {
				margin-bottom: 2px;
			}
		}
	}
}

/* LITTLE SIDEBARS */

.litl-side {
	border-top: 12px solid #ddd;
	border-bottom: 12px solid #ddd;
	padding: 24px 0 24px;
	margin-left: 40px;
	width: calc(33.333333333% - 40px);
	font-size: 18px;

	a {
		color: $black;
		font-size: 18px;
		font-weight: 300;
		text-decoration: underline;
	}

	h4:first-of-type {
		margin-top: 0;
	}

	ul {
		list-style: disc;
		overflow: visible;

		li {
			margin-bottom: 0;
			line-height: 130%;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}


/* MEMBER DASHBOARD */

.layout {
	.member-side {
		margin-left: 40px;
		width: calc(33.333333333% - 40px);
		padding: 0 0 20px;

		ul {
			display: block;
			list-style: none;
			border-top: 12px solid #ddd;
			border-bottom: 12px solid #ddd;
			padding: 0;
			margin-bottom: 15px;

			li {
				display: block;
				border-bottom: 1px solid #ddd;
				line-height: 120%;
				padding: 0;
				margin: 0;
				position: relative;

				&:last-child {
					border-bottom: 0;
					margin-bottom: 0;
				}
			}

			a {
				display: block;
				padding: 20px 25px 20px 0;
				position: relative;

				&:hover {
					&:after {
						color: $green3;
					}
				}

				&:after {
					display: block;
					content: '❯';
					color: $orange2;
					position: absolute;
					right: 5px;
					top: 50%;
					margin-top: -11px;
					font-size: 22px;
					font-weight: 400;
				}
			}
		}

		a.pplink {
			font-family: $ssp;
			font-weight: 400;
			text-decoration: underline;
			font-size: 12px;
			margin-top: 15px;
		}
	}
}

.dash-sub {
	display: block;
	background: #668d55;
	padding: 45px 10px;
	margin-top: 45px;
}

span.dash-btn {
	display: block;
	padding: 25px 10px;
	font-size: 26px;
	color: $orange2;
	text-decoration: underline;
	background: #dedede;
	font-family: $roboto;
	margin-top: 7px;
}

.dash-small {
	line-height: 140%;
	display: block;
	margin-top: 30px;
}

@media screen and (max-width: 767px) {
	.layout .member-side {
		margin-left: 0;
		margin-top: 35px;
		width: 100%;
		padding: 0 15px;
	}
}

/* SEARCH */

.search {
	.layout {
		p {
			margin-top: 8px;
			margin-bottom: 0;
			font-size: 16px;

			br {
				display: none;
			}
		}
	}
}

/* FIELDPRINT PROJECTS */

#vmap-intro {
	background: #e7e7e7;
	display: block;
	padding: 15px 20px;
	max-width: 660px;
	margin: 0 auto 60px;

	#fieldprint-project-directory & {
		max-width: 810px;
		padding: 15px 30px;
	}

	a.btn.bg-orange {
    	float: right;
    	display: block;
    	width: 130px;
    	font-family: $roboto;
    	margin-top: 7px;
    	@include rounded(0);

    	&:hover {
    		background: $orange3;
    		color: $white;
    	}
    }

    span {
    	padding-right: 16px;
    	display: inline-block;
    	width: calc(100% - 150px);
    	border-right: #a4a4a4 dashed 1px;
    	font-size: 16px;
    }
}

#vmap {
	display: block;
	width: 100%;
    height: 500px;
    margin-bottom: 60px;
}

.jqvmap-label {
	position: absolute;
	display: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #292929;
	color: white;
	font-family: sans-serif, Verdana;
	font-size: smaller;
	padding: 3px;
  pointer-events:none;
}

.jqvmap-pin {
  pointer-events:none;
}

.jqvmap-zoomin, .jqvmap-zoomout {
	position: absolute;
	left: 10px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background: #000000;
	padding: 3px;
	color: white;
	width: 10px;
	height: 10px;
	cursor: pointer;
	line-height: 10px;
	text-align: center;
}

.jqvmap-zoomin {
	top: 10px;
}

.jqvmap-zoomout {
	top: 30px;
}

.jqvmap-region {
  cursor: pointer;
}

.jqvmap-ajax_response {
  width: 100%;
  height: 500px;
}

#fieldprint-projects, #fieldprint-project-directory {
	display: block;
	padding-top: 126px;
	margin-bottom: 100px;

	.row {
		margin-bottom: 0;
	}

	.section-header {
		margin-bottom: 120px;
	}

	.search-filter-results {
		padding-top: 10px;

		a {
			display: block;
			text-decoration: none;
			padding: 20px 0;
			border-bottom: dashed 1px #dadada;

			&:hover {
				text-decoration: none;
			}

			&:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.project-icon {
		float: left;
		width: 25%;
		display: block;
		padding-left: 25px;
		padding-right: 0;
	}
}

#fieldprint-project-directory {
	background: #fff url(../images/bg-big-low-left.jpg) 30% top no-repeat;
	background-size: 100% 760px;
	padding-top: 445px;
	margin-top: -260px;
	display: block;
	margin-bottom: 20px;

	.section-header {
		margin-bottom: 160px;
	}

	.project-icon {
		float: left;
		width: 80px;
		display: block;
		padding-left: 0;
		padding-right: 0;

		img {
			width: 100%;
		}
	}
}

#project-filter {
	border-top: solid 1px #dadada;
	border-bottom: solid 1px #dadada;
	height: 335px;
	display: block;
	max-width: 760px;
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;

	#fieldprint-project-directory & {
		border-width: 2px;
		max-height: 500px;
		min-height: 1px;
		height: auto;
	}
}

.filter-left {
	border-right: dashed 1px #dadada;
	padding: 0 0 20px;
	padding-right: 0 !important;
	height: 335px;

	.page-template-_template-members-documents-resources & {
		height: auto;
	}
}

#search-filter-form-1556 .sf-field-post-meta-cf-project-state {
	position: absolute;
	left: -9999px;
	@include opacity(0);
}

.filter-right {
	overflow: scroll;
	height: 335px;

	#fieldprint-project-directory & {
		height: auto;
		max-height: 500px;
	}

	.page-template-_template-members-documents-resources & {
		height: auto;
	}
}

.project-summary {
	margin-left: 25%;
	padding-left: 16px;
	display: block;
	text-align: left;
	text-decoration: none;
	font-weight: 400;
	color: $black;
	font-size: 16px;

	&:hover {
		text-decoration: none;
	}

	#fieldprint-project-directory & {
		margin-left: 90px;
	}

	span {
		color: $orange2;
		text-decoration: underline;
	}
}

.searchandfilter {

	#fieldprint-project-directory & {
		position: absolute;
		left: -9999px;
	}

	label {
		width: calc(100% - 20px);
		font-weight: 400;
		font-size: 16px;
	}

	select.sf-input-select {
		font-weight: 400;
		font-size: 16px;
		width: 100%;
	}

	input.sf-input-text {
		padding-left: 8px;
		padding-right: 8px;
		width: 100%;
	}
}

.filter-no-results {
	font-style: italic;
	color: #70a0aa;
	margin-left: 20px;
	margin-top: 10px;
	display: block;
}

@media (max-width: 1695px) {

	#fieldprint-project-directory {
		background-size: 1700px 719px;
	}
}

@media screen and (max-width: 1024px) {
	.project-summary {
		font-size: 14px;
	}
}

@media screen and (max-width: 767px) {

	#vmap-intro {
		margin-bottom: 25px;
	}

	#vmap {
		height: 300px;
		margin-bottom: 45px;
	}

	#project-filter {
		height: auto;
		display: block;
		max-width: 100%;
		overflow: hidden;
		border-top: none;
		border-bottom: none;
	}

	.filter-left {
		border-right: none;
		padding: 0 0 20px;
		overflow: hidden;
		height: auto;
		@include transition(all 0.15s ease);
	}

	.filter-right {
		border-top: 1px solid #dadada;
		height: auto;
		overflow: auto;
	}

	h3.filter-head {

		/* &:after {
			display: block;
			width: 20px;
			height: 24px;
			font-size: 24px;
		    content: "+";
		    position: absolute;
		    right: 15px;
		    top: 6px;
		    z-index: 100;
		} */
	}

	/* h3.filter-head.active:after {
	    content: "-";
		font-size: 32px;
		top: 0px;
	} */

	.project-summary {
		font-size: 13px;
	}

	#fieldprint-projects .project-icon {
	    width: 17%;
	    padding-left: 0;
	}

	.project-summary {
    	margin-left: 17%;
	}
}


#resource-filter {
	.filter-left {
		border: none;
		font-size: 15px;
	}

	.filter-right {
		padding-left: 40px;
	}

	label {
		font-size: 14px;
	}
}

#search-filter-form-2579 {

	.sf-field-search {
		background: #ededed;
		border-top: 12px solid #d9d9d9;
		padding: 15px 10px 15px;
    	margin-bottom: 0;
    	border-bottom: 25px solid $white;
	}

	> ul {
		margin: 0;
		padding: 0 0 15px;
		background: #ededed;

		> li {
			padding: 0 15px 0;
		}

		li {

			ul {
				margin: 0;
				padding-left: 16px !important;

				ul {
					padding-left: 0 !important;
				}
			}

			li {
				margin: 5px 0;
				label {
					font-weight: 700;
				}
				li {
					margin: 0;
					label {
						font-weight: 400;
					}
				}
			}
		}

		.children {
			margin-left: 22px;
		}
	}

	.sf-field-tag {
		padding: 0;

		label {
			display: none;
		}
	}
}

#search-filter-results-2579 .resource {
	display: block;
	margin-bottom: 45px;

	&:hover {
		text-decoration: none;

		.project-icon span {
			text-decoration: none;
		}
	}

	.project-icon {
		float: left;
		width: 80px;
		display: block;
		padding-left: 0;
		padding-right: 0;
		text-decoration: none;

		img {
			width: 100%;
		}
	}

	.project-summary {
	    margin-left: 80px;
	    padding-left: 16px;
	    text-align: left;
	    font-weight: 400;
	    color: #151413;
	    font-size: 16px;
	}
}

span.download, span.video {
	color: $white;
	display: block;
	font-size: 12px;
	font-family: $roboto;
	font-weight: 400;
	background: #ff9300 url('../images/icon-download@2x.png') no-repeat 5px 5px;
	background-size: 9px 9px;
	line-height: 19px;
    height: 19px;
    padding: 0 0 0 20px;
}

span.video {
	background: #ff9300 url('../images/icon-view@2x.png') no-repeat 19px 5px;
	background-size: auto 10px;
	padding-left: 40px;
}

.download-blk {
	display: block;
	width: 100%;
	margin: 0 0 45px 0;
	min-height: 80px;

	p {
		margin: 0;
		font-size: 16px;
		color: #999999;
	}

	h2 {
		margin: 8px 0 10px 100px;

	}
}

.video-btn span {
	background: url('../images/');
}

.download-btn {
	float: left;
	display: block;
	width: 80px;
	margin-right: 20px;

	&:hover {
		text-decoration: none;
	}
}

#edit-project {
	display: block;
	margin: 50px 0;
	padding: 35px;

	.edit-wrap {
		padding: 0 15px;
	}

	header {
		margin: 35px 0 0;
		border-bottom: 2px dashed #ccc;
		background: #ddd;
		padding: 20px 15px 10px;

		p {
			font-style: italic;
			font-size: 16px;
			line-height: 135%;
			margin: 0 0 10px;
		}
	}
}

.fieldprint-project-registration #message.updated {
	color: $green3;
	font-weight: 700;
	font-size: 24px;
	display: block;
}

#acf-form {
	width: 100%;
	padding: 25px;
	font-size: 18px;
	background: #eee;

	.acf-fields .acf-field-58280af855719 {
		display: none !important;
	}

	.acf-fields > .acf-tab-wrap .acf-tab-group {

	}

	.acf-form-submit {
	    background: #eee;
	    display: block;
	    height: 40px;
	    margin: 20px 0;
	}
}

.single-cpt-projects {
	.content-col.col-lg-8 {
		padding-right: 40px;
	}
}

.project-side {
	width: calc(33.333333333%);
	border-top: 12px solid #ddd;
	border-bottom: 12px solid #ddd;
	padding: 24px 0 24px;

	h3:first-of-type {
		margin-top: 14px;
		margin-bottom: 0px;
	}

	h4 {
		margin-top: 30px;
	}

	ul {
		margin-top: 8px;
		li {
			margin-bottom: 2px;
			line-height: 120%;
		}
	}
}

.single-proj-footer {
	display: block;
	border-top: 1px solid #979797;
	padding-top: 16px;
	clear: both;
	margin-top: 60px;
}

/* EDIT USER */

#user-post-lists {
	h4 {
		margin-bottom: .5em;
		margin-top: 1em;
	}

	a {
		color: $black;
		text-decoration: underline;
		font-size: 16px;
		font-weight: 400;
	}
}

.user-post-list {
	list-style: none;
	padding-left: 0 !important;
	font-size: 16px;
	margin: 0;

	em {
		color: $blue1;
	}
}

/* MEMBER LIST */

#member-list {
	form {
		padding: 20px 30px 25px;
		background: #395969;
		color:white;

		input, select {
		  width:100%;
		  color:$black;
		  height: 26px;
		  border: 0;
		  max-width: 320px;
		}

		select {
			font-size: 15px;
		}

		input {
			padding: 0 8px;
			margin-top: 3px;
		}
	}

	a {
		color: $black;
		font-weight: 300;
		display: block;
		padding: 20px 0 20px;

		&:hover {
			text-decoration: none;
		}
	}
}

ul.member-directory__results {
	margin-top:60px;
	padding-left:0;

	li.member-directory__results__member {
		border-bottom:1px solid $gray;
		margin-bottom: 0;
		padding-bottom: 0;
		line-height: 160%;
	}

	h5.member-directory__results__member__name-title {
		margin-bottom: 0;
		line-height: 170%;
	}

	.member-directory__results__member__organization-sector {
	}
}

.author {

	.content-col {
		padding-bottom: 10px;
		p {
			margin-top: 1em;
		}
	}

	h3:first-of-type {
		margin-top: 1.5em;
	}

	.author-title {
		font-size: 22px;
		border-bottom: 2px solid #979797;
		padding-bottom: 40px;
	}

	.author-tags {
		display: block;
		margin-top: 80px;
		margin-bottom: 0;
	}

	span.member-tag {
		display: inline-block;
		height: 28px;
		background: #d8d8d8 url('../images/icon-tag.png') no-repeat 7px 8px;
		background-size: 13.5px 13.5px;
		font-size: 13px;
		font-weight: 300;
		padding: 0 10px 0 28px;
		margin: 0 12px 12px 0;
	}
}

@media (max-width: $screen-lg-min) {
	#member-list {
		.col-xs-12 {
			margin-bottom: 20px;
		}
	}
}

@media screen and (max-width: 767px) {
	.litl-side, .project-side {
		margin-left: 0;
		margin-top: 35px;
		width: 100%;
		padding: 24px 20px;
	}
}


/* REPORT DATA */

.report-data {
	display: block;
	margin-top: 45px;
	padding: 10px 30px 30px;

	header {
		display: block;
		margin-top: 45px;
		margin-bottom: 30px;
	    background: #ddd;
	    padding: 25px 20px 15px;
	    border-bottom: 1px dashed #151413;

	    .desc {
	    	font-style: italic!important;
		    font-size: 16px;
		    line-height: 125%;
		    color: #6d6d6d;
		    margin-top: 7px;
		    margin-bottom: 9px;
	    }
	}
}

.table-wrap {
	padding: 0 24px;

	.section-head {
		display: block;
	}

	h5 {
		display: inline-block;
		border-bottom: 1px dashed $black;
		margin: 1em 0 0;
		padding-bottom: 2px;
		font-size: 20px;
	}
}

table.report-table {
	font-size: 14px;
    width: 100%;
    margin: 5px 0 45px;
    padding:0;
    border-collapse: collapse;
    border-spacing: 0;
    display: table;

    thead {
    	width: 100%;
    }

	th {
		font-size: 12px;
		line-height: 140%;
		vertical-align: bottom;
		text-align: center;
		font-weight: 400;
	}

	th, td {
		padding: 5px;
	}

	tbody {
		border-bottom: dashed 1px $black;
	}

	th, tr {
		border-top: dashed 1px $black;
	}

	tr {
		width: 100%;

		&:last-child {
		}
	}

	td {
		text-align: center;
		font-weight: 700;
	}
}


@media screen and (max-width: 600px) {

	.table-wrap {
		padding: 0;
	}

    table.report-table  {
    	thead {
    		display: none;
    	}

    	tr {
			margin-bottom: 10px;
			display: block;
			border-bottom: 2px solid #ddd;

			&:last-child {
				margin-bottom: 0;
				border-bottom: none;
			}
		}

		td {
			display: block;
			text-align: right;
			font-size: 13px;
			border-bottom: 1px dotted #ccc;
			font-weight: 400;

			&:before {
				content: attr(data-label);
				float: left;
				text-transform: uppercase;
				font-weight: bold;
			}
	    }
    }
}

.sig-img {
	width: 150px;
}

ul#verify-list {
	list-style: none;
	margin: 0;
	padding-left: 0;

	li {
		display: block;
		margin: 0 0 8px;
		font-weight: 700;
		line-height: 130%;
		background: url(../images/icon-checkbox.png) no-repeat 0 3px;
		padding-left: 25px;
		background-size: 18px 18px;
	}
}

div.social-wrap {
	display: block;
	margin-top: 50px !important;
}

.social-icons {
    display: inline-block;
    background-color: #eaeaea;

    > div {
    	padding-top: 10px;
	    margin-right: 10px;
	    margin-bottom: 0px;
	    margin-left: 10px;
    }
}

#press-release-carousel {
	margin-bottom: 100px;

	a.item {
		display: block;
		min-height: 225px;
		background: #eaeaea;
		color: $black;
		font-family: $ssp;
		padding: 30px;
		font-weight: 300;
		text-decoration: none;
		@include transition(all 0.15s ease);
		font-size: 16px;

		&:hover {
			text-decoration: none;
			background: #dadada;
			@include transition(all 0.25s ease);
		}

		span.more {
			font-weight: 700;
			color: $orange2;
		}

		p {
			margin: 10px 0 0 !important;
			line-height: 150%;
		}
	}
}

#search-filter-form-2579 li[data-sf-field-type="taxonomy"] {

	ul {
		transition: height .3s;
		overflow:hidden;
		padding-left: 10px;
	}

	ul.shrink {
		height:0 !important;
	}

	.toggle {
		display: block;
		cursor:pointer;
		position: absolute;
		left: 0;
		width: 100%;
	}
}
