/*********************
NAVIGATION
*********************/

#member-bar {
	display: block;
	color: $black;
	height: 39px;
	line-height: 39px;
	text-align: right;

	.docked & {
		background: $white;
		height: 29px;
		line-height: 29px;
	}

	> .container {
		position: relative;
	}

	.sep {
		height: 29px;
		display: inline-block;
		width: auto;
		background: $white;
		width: 10px;
		text-align: center;
		line-height: 29px;
		font-size: 13px;
		vertical-align: top;
	}

	.btn-calculator {
		font-family: $nexa;
		position: relative;
		vertical-align: top;
		font-size: 13px;
		line-height: 28px;
		height: 29px;
		text-transform: uppercase;
		@include rounded(0);
		color: #000;
		font-weight: 700;
		background: $white;
		margin: 0 -5px 0 0;
		padding: 0 17px 0 0;
		@include transition(color 0.05s ease-in);
		@include transition(padding 0.16s ease-in);

		&:before {
			content: '';
    		height: 29px;
    		width: 25px;
    		background: url('../images/bg-left-search.png') no-repeat;
    		background-size: cover;
    		position: absolute;
    		top: -1px;
    		left: -25px;
		}

		&:after {
			display: inline-block;
			content:"|";
			position: absolute;
			right: 5px;
			color: $black;
			font-size: 14px;
			z-index: 14;
			height: 29px;
			line-height: 28px;
			font-weight: 400;
		}

		.logged-in & {
			font-weight: 700;
			text-decoration: underline;
			font-family: $ssp;
			line-height: 30px;
			padding-left: 10px;
			margin-right: -5px;
		}

		&:hover {
			@include transition(color 0.15s ease-in);
			color: $orange2;
		}
	}

	.btn-member-entry {
		font-family: $nexa;
		position: relative;
		vertical-align: top;
		font-size: 13px;
		line-height: 28px;
		height: 29px;
		text-transform: uppercase;
		@include rounded(0);
		color: #000;
		font-weight: 700;
		background: $white;
		margin: 0;
		padding: 0 40px 0 3px;
		@include transition(color 0.05s ease-in);

		.logged-in & {
			padding-right: 4px;
			margin-right: -5px;
			font-weight: 700;
			text-decoration: underline;
			font-family: $ssp;
			line-height: 29px;
			padding-left: 3px;

			&:after {
				display: none;
			}
		}

		&:hover {
			@include transition(color 0.15s ease-in);
			color: $orange2;

			&:after {
				color: $black;
			}
		}
	}

	.btn-logged-in {
		font-family: $ssp;
		font-weight: 700;
		text-decoration: underline;
		position: relative;
		vertical-align: top;
		font-size: 13px;
		line-height: 29px;
		height: 29px;
		text-transform: uppercase;
		@include rounded(0);
		color: #000;
		background: $white;
		margin: 0 -1px 0 0;
		padding: 0 0 0 17px;
		@include transition(color 0.05s ease-in);
		@include transition(padding 0.16s ease-in);

		&:before {
			display: inline-block;
			content:"|";
			position: absolute;
			left: 5px;
			color: $black;
			font-size: 14px;
			z-index: 14;
			height: 29px;
			line-height: 28px;
			font-weight: 400;
		}

		&:hover {
			@include transition(color 0.15s ease-in);
			color: $orange2;
		}

		&.btn-sign-out {
			padding-right: 40px;
		}
	}

	.btn-dashboard {
		font-family: $ssp;
		font-weight: 700;
		text-decoration: underline;
		position: relative;
		vertical-align: top;
		font-size: 13px;
		line-height: 29px;
		height: 29px;
		text-transform: uppercase;
		@include rounded(0);
		color: #000;
		background: $white;
		margin: 0 -5px 0 0;
		padding: 0 3px 0 15px;
		@include transition(color 0.05s ease-in);

		.logged-in & {
			padding-left: 17px;
		}

		&:before {
			display: inline-block;
			content:"|";
			position: absolute;
			left: 5px;
			color: $black;
			font-size: 14px;
			z-index: 14;
			height: 29px;
			line-height: 28px;
			font-weight: 400;
		}

		&:hover {
			@include transition(color 0.15s ease-in);
			color: $orange2;
		}
	}

	.icon-user-outline {
		font-size: 18px;
		margin-right: 1px;
		&:before {
			margin-left: 0;
		}
	}
}

.input-wrapper {
	float: right;
	margin: 0;
	height: 29px;
	display: inline-block;
	width: 0;
	background: $white;
	overflow: hidden;
}

#s {
	width: 157px;
	height: 25px;
	line-height: 26px;
	font-weight: 400;
	font-style: normal;
	font-family: $ssp;
	font-size: 13px;
	border: 0;
	background: $black;
	color: $white;
	vertical-align: top;
	margin-top: 4px;
	padding: 0 48px 0 10px;

	&::-webkit-input-placeholder {
	   font-style: italic;
	}

	&:-moz-placeholder { /* Firefox 18- */
	   font-style: italic;
	}

	&::-moz-placeholder {  /* Firefox 19+ */
	   font-style: italic;
	}

	&:-ms-input-placeholder {
	   font-style: italic;
	}
}

.searchform {
    position: absolute;
	top: 10px;
	right: 0;
	width: 553px;
	z-index: 1;

	&.logged-in {
		width: 630px;

		&:after {
			right: -25px;
		}
	}

	.docked & {
		top: 0;
	}

    &:after {
		content: '';
		height: 29px;
		width: 25px;
		background: url('../images/bg-right-search.png') no-repeat;
		background-size: cover;
		position: absolute;
		top: 0px;
		right: -25px;
	}
}

.searchform a.btn-submit {
	position: absolute;
	right: 0;
	z-index: 13;
	display: inline-block;
    background: center no-repeat #ffffff;
    color: $black;
    float: right;
    width: 28px;
    height: 29px;
    margin: 0;
    border: none;
    cursor: pointer;
    padding: 0;

    .docked & {
    	height: 25px;
    	top: 4px;
    }

    .icon-search {
    	position: absolute;
    	left: 0;
    	right: 0;
    	top: -3px;
    	margin: auto;
    	font-size: 20px;
    	@include transition(all 0.05s ease-in);
    }

    &:hover  {
    	.icon-search {
    		color: $orange2;
    		@include transition(all 0.15s ease-in);
    	}
    }
}

.nav-elements {
	display: block;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9998;
	margin-top: -50px;
	padding-top: 50px;

	&.docked {
		position: fixed;
		top: 0;
		@include transition(margin .5s ease);
		margin: 0;
		padding: 0;
		box-shadow: 0 3px 13px 0 rgba(36,30,26,.3);
	}
}


#logo {
	display: inline-block;
	float: left;
	width: 216px;
	margin: 10px 0 0;

	.docked & {
		margin: 3px 0 0;
		width: 130px;
		overflow: hidden;

		.home & {
			margin: 0;
		}

		img {
			width: 110px;
		}
	}
}

#main-nav {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff6e00+0,ff9700+100 */
	background: #ff6e00; /* Old browsers */
	background: -moz-linear-gradient(left,  #ff6e00 0%, #ff9700 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #ff6e00 0%,#ff9700 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #ff6e00 0%,#ff9700 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff6e00', endColorstr='#ff9700',GradientType=1 ); /* IE6-9 */
	border-top: 4px solid $white;
	border-bottom : 4px solid $white;

	.docked & {
		border-top: 2px solid $white;
		border-bottom: 0;
	}
}

@media (min-width: 1025px) {
	#the-menu {
		display: inline-block;
		height: 72px;
		float: right;
		margin-right: -12px;

		.docked & {
			height: 36px;
		}

		.nav {
			border: 0;
			text-align: center;
			width: 100%;

			ul {
				margin: 0;
				text-align: center;
			}

			> li:hover > a {
				span {
					border-bottom: 3px solid $orange2;
				}
			}

			li {
				display: inline-block;
				position: relative;
				margin-right: 0;
				text-align: center;

				&:hover {
					background: $white;

					> a {
						color: $black;
					}
				}

				a {
					font-family: $ssp;
					font-weight: 700;
					height: 72px;
					line-height: 72px;
					border-bottom: 0;
					color: $white;
					font-size: 18px;
					padding: 0 12px;
					text-align: center;
					//text-transform: uppercase;

					&:hover, &:focus {
						background: none;
						text-decoration: none;
					}

					.docked & {
						height: 36px;
						line-height: 36px;
						font-size: 16px;
					}
				}

				&:first-child {}
				&:last-child {
					margin-right: 0;
				}

				ul.sub-menu,
				ul.children {
					margin-top: 0;
					border-top: 0;
					position: absolute;
					visibility: hidden;
					z-index: 8999;
					background-color: $white;
					padding: 0;
					text-transform: none;
					padding-top: 10px;
					padding-bottom: 10px;
					border-left: 1px solid #ededed;
					border-bottom: 1px solid #ededed;
					border-right: 1px solid #ededed;

					ul.sub-menu {
						left: 100%;
						top: 0;

						li:first-child {
							margin-top: 0;
						}

						li:last-child {
							margin-bottom: 15px;
						}

						a {
							text-transform: none;
							font-size: 13px;
						}
					}

					li {

						&:first-child {
							margin-top: 4px;
						}

						a {
							display: block;
							width: 226px;
							text-align: left;
							font-size: 15px;
							font-weight: 700;
							padding-left: 15px;
							color: $black;
							text-decoration: underline;
							height: 36px;
							line-height: 36px;
							margin-left: 12px;
							margin-right: 12px;
							padding-left: 8px;
							padding-right: 8px;

							&:before {
								display: inline-block;
								content: '>';
								font-size: 13px;
								color: $black;
								margin-right: 5px;
							}

							&:hover,
							&:focus {
								background: #ebebeb;
								@include transition(all 0.1s ease-in);
							}

							&:link {}
						}
					}
				}

				/* showing sub-menus */
				&:hover {
					> ul {
						top: auto;
						visibility: visible;
						background: $white;
					}
				}

				&.menu-the-alliance {

					> ul {
						width: 444px;
						text-align: left;

						a {
							width: 206px !important;
						}

						li.menu-governance, li.menu-staff, li.menu-events, li.menu-annual-report {
							width: 50%;
							.sub-menu {
								left: 0 !important;
								padding-top: 0;
								padding-bottom: 0 !important;

								a {
									padding-left: 20px;
									padding-right: 16px;
								}

								li:last-child {
									margin-bottom: 0 !important;
								}
							}
						}
						li.menu-staff, li.menu-events, li.menu-annual-report {
							position: absolute;
							left: 50%;
							a {
								margin-left: 0;
								margin-right: 0;
							}
						}
						li {
							&.menu-staff {
								top: 14px;
							}
							&.menu-events {
								top: 85px;
							}
							&.menu-annual-report {
								top: 122px;
							}
						}

						&.sub-menu {
							text-align: left;

							ul.sub-menu {
								position: relative;
								left: 0 ;
								border: 0;
								text-align: left;

								li {
									border: 0;

									a {
										border: none;
										font-weight: 400;

										span {

										}

										&:before {
											display: none;
										}
									}
								}
							}
						}
					}

					&:hover {
						> ul {
							top: auto;
							visibility: visible;
							background: $white;

							&.sub-menu {

								ul.sub-menu {
									visibility: visible;
								}
							}
						}
					}
				}

			} /* end .menu ul li */

			/* highlight current page */
			li.current-menu-item,
			li.current_page_item,
			li.current_page_ancestor {
				a {

				}
			}  /* end current highlighters */

		} /* end .nav */
	}

	#btn-mobile-menu {
		display: none;
	}
}

@media (max-width: 1024px) {

	.mm-listview > li > a, .mm-listview > li > span {
	    padding: 7px 10px 7px 15px;
	}

	#mm-the-menu {
		li a {
			font-size: 16px;
		}
	}

	.mm-navbar .mm-btn:first-child {
	    left: 0;
	    top: 140px;
	}

	.mm-navbar .mm-title {
	    padding-top: 150px;
	    z-index: 150;
	}

	.mm-panel.mm-hasnavbar .mm-navbar {
	    display: block;
	    height: 86px;
	    border-bottom: $white 3px solid;
	    background: $orange2 url('../images/logo-ftm@2x.png') center 10px no-repeat;
	    background-size: auto 60px;
	}

	#btn-mobile-menu {
		border: 2px solid $white;
		display: block;
		width: 25px;
		background: $orange2;
		top: 15px;
		left: 20px;
		position: absolute;
		z-index: 9900;
		overflow: hidden;
		@include circle(50px);
		padding-top: 9px;

		span {
			background: $white;
			display: block;
			width: 30px;
			height: 3px;
			margin: 5px 0 0 9px;
			position: relative;
			transform: rotate( 0 );
			top: 0;
			left: 0;
			opacity: 1;
			transition: none 0.5s ease;
			transition-property: transform, top, left, opacity;
		}

		html.mm-opened & {

			span.top-bar {
			   transform: rotate( 45deg );
			   top: 7px;
			}

			span.middle-bar {
			   opacity: 0;
			   left: -40px;
			}

			span.bottom-bar {
			   transform: rotate( -45deg );
			   top: -8px;
			}
		}
	}
}

@media (max-width: $screen-lg-min) {

	.container {
		.nav-elements & {
			width: 96%;
		}
	}

	#the-menu {
		.nav {
			li {
				a {
					font-size: 16px;
				}
			}
		}
	}
}

@media (max-width: $screen-md-min) {

	#the-menu {
		.nav {
			li {
				a {
					font-size: 15px;
				}
			}
		}
	}
}

@media (max-width: 950px) {

	#the-menu {
		.nav {
			li {
				a {
					padding: 0 8px;
				}
			}
		}
	}
}

@media (max-width: 900px) {

	#the-menu {
		.nav {
			li {
				a {
					font-size: 14px;
				}
			}
		}
	}
}

@media (max-width: 600px) {
	#btn-mobile-menu {
		width: 21px;
		top: 15px;
		left: 20px;
		@include circle(42px);
		padding-top: 6px;

		span {
			width: 20px;
			height: 3px;
			margin: 4px 0 0 9px;
		}
	}
}


/*********************
PAGE TITLE/BANNER
*********************/

#page-title {
	position: relative;
	z-index: -1;
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	padding-top: 250px;
	height: 466px;

	.site-map &, .error404 &, .page &, .single-post &, .fieldprint-project-registration & {
		background-image: url('../images/bg-page-default.jpg');
	}

	.search & {
		background-image: url('../images/bg-press-releases.jpg') !important;
	}

	.single-cpt-projects & {
		background-image: url('../images/bg-fieldprint-projects-single.jpg');
	}

	.single-cpt-annual-reports &, .single-cpt-mcrs & {
		background-image: url('../images/bg-head-annual-report.jpg');
	}

	.single-cpt-projects.logged-in & {
		background-image: url('../images/bg-fieldprint-platform.jpg');
	}

	.author & {
		background-image: url('../images/bg-our-members.jpg');
	}

	.single-cpt-resources & {
		background-image: url('../images/bg-documents-resources@2x.jpg');
	}

	.caption {
		position: absolute;
		right: 10px;
		bottom: 10px;
		font-style: italic;
		font-size: 12px;
		color: $white;
	}
}

@media (max-width: $screen-md-min) {

	#page-title {
		padding-top: 130px;
		height: 326px;
	}
}

@media (max-width: 767px) {
	#page-title {
		padding-top: 100px;
		height: 250px;
	}
}

@media (max-width: 480px) {
	#page-title {
		padding-top: 90px;
		height: 200px;
	}

}
