
/*********************
PRELOADER
*********************/

body {
    overflow: hidden;
}

#the-menu .nav li.mobile {
	display: none;
}

@media (max-width: 1024px) {
   #the-menu .nav li.mobile {
   		display: block;
   }
}

/* Preloader */

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 9999;

    span {
    	display: block;
    	height: 30px;
    	position: absolute;
    	background: url('../images/logo-ftm.png') no-repeat;
    	background-size: 130.2px 30px;
    	width: 42px;
    	left: 0;
    	right: 0;
    	top: 0;
    	bottom: 0;
    	margin: auto;
    }
}

#status {
    width: 150px;
    height: 150px;
    position:absolute;
    left: 50%;
    top: 50%;
    background-position: center;
    margin:-75px 0 0 -75px; /* is width and height divided by two */
    background: url('../images/bg-preloader-blue.jpg') no-repeat;
	background-size: contain;
}

.spinner {
	display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 96px;
    height: 96px;
    margin: -48px 0 0 -48px;
    border: 2px solid transparent;
    border-top-color: $white;
    z-index: 1500;
    border-radius: 50%;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;

    &:before {
	    content: "";
	    position: absolute;
	    top: 5px;
	    left: 5px;
	    right: 5px;
	    bottom: 5px;
	    border: 2px solid transparent;
    	border-top-color: $white;
    	border-radius: 50%;
    	-webkit-animation: spin 1.5s linear infinite;
    	animation: spin 1.5s linear infinite;
	}

	&:after {
	    content: "";
	    position: absolute;
	    top: 15px;
	    left: 15px;
	    right: 15px;
	    bottom: 15px;
	    border: 2px solid transparent;
    	border-top-color: $white;
    	border-radius: 50%;
    	-webkit-animation: spin .75s linear infinite;
		animation: spin .75s linear infinite;
	}
}

@media (max-width: $screen-md-min) {
   .container {
      width: 90%;
   }
}


/*********************
MMENU
*********************/

@media (max-width: 1024px) {
   #the-menu, #member-bar, #main-nav {
      display: none !important;
   }
}
@media (min-width: 1025px) {
   #mm-the-menu{
      display: none !important;
   }
}

/* include this only once */
@-webkit-keyframes spin {
	0%   {
	    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
	    -ms-transform: rotate(0deg);  /* IE 9 */
	    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
	}
	100% {
	    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
	    -ms-transform: rotate(360deg);  /* IE 9 */
	    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
	}
	}
	@keyframes spin {
	0%   {
	    -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
	    -ms-transform: rotate(0deg);  /* IE 9 */
	    transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
	}
	100% {
	    -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
	    -ms-transform: rotate(360deg);  /* IE 9 */
	    transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
	}
}

/*********************
GENERAL
*********************/

body {
	background: $white;
	font-family: $ssp;
	font-size: 18px;
	font-weight: 300;
}

.center {
	text-align: center;
}

.not-bold {
	font-weight: 400 !important;
}

.underline {
	text-decoration: underline;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.float-right {
	float: right;
}

.bg-lt-gray {
	background: #e7e7e7;
}

.bg-ddd {
	background: #ddd;
}

.bg-white {
	background: $white;
}

.bg-green {
	background: $green0;
}

.bg-orange {
	background: $orange2;
}

.bg-teal {
	background: $teal1;
}

.bg-blue-slate {
	background: #a4bcca;
}

.white {
	color: $white;
}

.black {
	color: $black;
}

.slate-blue {
	color: #6190b1;
}

p {
	margin: 0 0 20px;
}

.no-top {
	padding-top: 0 !important;
}

/*********************
LAYOUT
*********************/

.main {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.section {
	padding: 75px 0;

	h2:first-of-type {
		margin-top: 0;
	}

	ul {

	}
}

.section-header {
	display: block;
	margin-bottom: 30px;

	#home-become-member & {
		margin-bottom: 20px;
	}

	#member-rotator & {
		text-align: center;
	}
}

// Contain floats: nicolasgallagher.com/micro-clearfix-hack/
.clearfix, .cf {
  zoom: 1;
    &:before, &:after { content: ""; display: table; }
    &:after { clear: both; }
}

.no-pad {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.no-left-pad {
	padding-left: 0 !important;
}

.no-right-pad {
	padding-right: 0 !important;
}

@media (max-width: $screen-md-min) {
	.section {
	    padding: 60px 0;
	}
}

@media (max-width: $screen-sm-min) {
	.section {
	    padding: 50px 0;
	}
}

@media (max-width: 480px) {
	.section {
	    padding: 30px 0;
	}
}


/*********************
LINK STYLES
*********************/

a {
	text-decoration: none;
	outline: none;
	color: $orange2;
	font-weight: bold;

	/* on hover */
	&:hover, &:focus {
		@include transition(color 0.15s ease);
		color: $green4;
		outline: none;
		text-decoration: underline;

		span {
			outline: none;
		}
	}

	/* on click */
	&:active {
		outline: none;
	}

	/* mobile tap color */
	&:link {
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
		outline: none;
	}

	&.right-caret {
		border-bottom: $orange2 2px solid;
		padding-bottom: 2px;
		position: relative;

		&:hover, &:focus {
			border-bottom-color: $green4;
			text-decoration: none;
		}

		&:after {
			display: inline-block;
			content: '>';
			position: absolute;
			right: -16px;
		}
	}
}

.align-right {
	text-align: right;
}

button {
	outline: none;
}


/*********************
HEADERS
*********************/

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {

	&.first {
		margin-top: 0 !important;
	}

	&.white-hash {
		display: inline-block;

		&:after {
			display: block;
			content: '';
			height: 8px;
			background: url(../images/bg-white-hashes@2x.png) repeat-x;
			background-size: auto 8px;
		}
	}

	&.gray-hash {
		display: inline-block;
		margin: 80px 0 10px;

		&:after {
			display: block;
			content: '';
			height: 8px;
			background: url(../images/bg-gray-hashes@2x.png) repeat-x;
			background-size: auto 8px;
		}
	}

	&.black-hash {
		display: inline-block;

		&:after {
			display: block;
			content: '';
			height: 8px;
			background: url(../images/bg-black-hashes@2x.png) repeat-x;
			background-size: auto 8px;
		}
	}
}

h1, .h1 {
	font-family: $nexa;
	font-size: 48px;

	#home-video & {
		margin-top: 0;
		color: $white;
		max-width: 850px;
		margin: 0 auto;
		line-height: 135%;
		font-size: 48px;

		span {
			position: relative;

			&:after {
				content: '';
				border-bottom: 5px solid #ff8937;
				display: block;
				position: absolute;
				bottom: -7px;
				width: 100%;
				left: 0;
			}
		}
	}

	#page-title & {
		margin: 0;
		color: $white;
		padding: 0 0 0 75px;

		sup {
			top: -0.8em;
			font-size: 50%;
		}
	}

	.page-content & {
		font-size: 36px;
		margin-top: 2em;
		margin-bottom: 1em;
	}
}

h2, .h2 {
	font-family: $roboto;
	font-weight: 700;
	font-size: 52px;

	sup {
		font-size: 50%;
		top: -.8em;
	}

	.home & {
		display: inline-block;
	}

	.carousel & {
		margin-left: 40px;
	}

	#home-tools-resources &, #home-program &, #home-partner-impact &, #member-rotator & {
		font-size: 42px;
	}

	#our-program-benchmark &, #our-program-catalyzing &, #our-program-enabling &, #join-us-intro &, #join-us-member-types & {
		font-size: 38px;
		margin-bottom: 70px;
	}

	#join-us-intro & {
		margin-bottom: 100px;
		margin-right: 18%;
    	margin-left: 5%;
	}

	#member-rotator & {
		margin-left: 0;
		font-weight: 400;
	}

	#home-become-member & {
		font-size: 62px;
		line-height: 100%;
	}

	#home-member-spotlight & {
		margin-bottom: 50px;
	}

	.layout-wide-top-bg & {
		margin-bottom: 160px;
	}

	&.white-hash {

		&:after {
			margin-top: 6px;
		}
	}

	&.black-hash {

		&:after {
			margin-top: 6px;
		}
	}

	.layout-two-col-text & {
		font-size: 42px;
	}

	#our-program-catalyzing &, #our-program-enabling & {
		padding-right: 100px;
		margin-bottom: 85px;
	}

	#our-program-enabling & {
		margin-bottom:70px;
	}

	#join-us-benefits & {
		margin-bottom: 120px;
	}

	.with-form & {
		font-size: 40px;
	}

	#benefits &, #fprr &, #fprr-measurement & {
		margin-bottom: 80px;
	}

	#fprr &, #fprr-measurement & {
		font-size: 42px;
		margin-bottom: 70px;
	}

	#fprr-measurement & {
		padding-right: 80px;
	}

	#farmer-spotlight & {
		display: inline-block;
	}

	#how-does-it-work & {
		padding-left: 200px;
		margin-bottom: 80px;
	}

	#fieldprint-cta & {
		font-size: 42px;
		margin-bottom: 30px;
	}

	.page-content & {
		font-size: 30px;
		margin-top: 2em;
		margin-bottom: 1em;
	}

	.single-cpt-projects & {
		font-size: 28px;
		font-weight: 400;
	}

	.author & {
		font-size: 28px;
		font-weight: 700;
		margin-bottom: 0;
	}

	.report-data & {
		margin: 0;
		font-size: 24px;
	}

	#news-press-news & {
		margin-bottom: 80px;
	}

	#twitter-blk & {
		font-size: 34px;
	}

	.single-post & {
		margin-bottom: 5px;
		font-weight: 400;
		font-family: $ssp;
		font-size: 26px;
	}

	#event-list-upcoming &, #event-list-past & {
		margin-bottom: 60px;
	}

	#edit-project & {
		font-size: 26px;
		margin: 0 0 12px;
	}
}

h3, .h3 {
	font-family: $roboto;
	font-weight: 700;
	font-size: 36px;
	position: relative;

	#home-feeds & {
		border-bottom: 5px solid $black;
		padding-bottom: 8px;
		font-weight: 400;
	}

	.layout-two-col & {
		font-weight: 400;
		font-size: 28px;
		display: block;
		margin-top: 10px;
		margin-bottom: 32px;

		&:after {
			margin-top: 16px;
		}
	}

	&.blue-punc {
		color: #678071;
		font-weight: 700;
		text-align: center;
		font-size: 28px;
		margin: 30px 0 35px;
		line-height: 130%;
	}

	.benefit & {
		font-size: 28px;
		font-weight: 400;
		margin-bottom: 15px;
	}

	#join-us-member-rows & {
		font-weight: 400;
		font-size: 28px;
		margin-bottom: 30px;
	}

	.page-id-9 & {
		color: $black;
		font-size: 30px;
		margin-bottom: 30px;
		line-height: 140%;
	}

	.get-in-touch-form & {
		font-weight: 400;
		margin-top: 0;
		padding-top: 0;
		line-height: 100%;
	}

	#gov-sectors & {
		font-size: 28px;
		font-weight: 400;
		margin-bottom: 22px;
		margin-top: 45px;
	}

	.gform_body & {
		font-size: 30px;
		margin-top: 20px;
	}

	.page-content & {
		font-size: 26px;
		margin-top: 2em;
		margin-bottom: 1em;
	}

	&.underline-gray {
		border-bottom: 3px solid #ccc;
		padding-bottom: 12px;
	}

	.page-content .member-side & {
		margin-top: 1.2em;
		font-size: 27px;
	}

	.dash-sub & {
		margin-bottom: 0;
	}

	#project-filter .filter-left & {
		color: $white;
		background: #70a0aa;
		font-size: 20px;
		margin: 0;
		padding: 8px 16px;
		position: relative;
	}

	&.states-result {
		display: block;
		font-size: 28px;
		margin-bottom: 25px;
		margin-top: 30px;

		span {
			color: $orange2;
			text-transform: capitalize;
		}
	}

	.single-cpt-projects & {
		font-weight: 300;
		font-size: 24px;

		&.black {
			font-family: $ssp;
			font-weight: 700;
			font-size: 21px;
		}
	}

	.single-cpt-projects .project-side & {
		font-size: 22px;
	}

	.author & {
		font-family: $ssp;
		color: #6190b1;
		font-weight: 300;
		font-size: 24px;
		margin-top: 1.5em;
		margin-bottom: .75em;
	}

	.single-post & {
		font-size: 18px;
		font-family: $ssp;
	}

	.date & {
		font-size: 24px;
		margin-top: 45px;
		text-decoration: underline;
	}

	#event-list-upcoming ul &, #event-list-past ul & {
		font-weight: 400;
	}

	#edit-project & {
		font-size: 20px;
		margin: 30px 0 12px;
	}
}

h4, .h4 {
	font-family: $ssp;
	font-size: 20px;
	font-weight: 700;

	#home-program & {
		margin-top: 18px;
		margin-bottom: 2px;
	}

	footer.content-info & {
		font-size: 24px;
	}

	.owl-carousel & {
		font-size: 30px;
		margin-top: 0;
		margin-bottom: 25px;
	}

	#our-program-enabling-tri & {
		font-weight: 300;
		margin-top: 50px;
		margin-bottom: 30px;
	}

	#our-program-intro-tri & {
		margin-bottom: 4px;
	}

	.gov-sector & {
		font-family: $roboto;
		font-weight: 400;
		font-size: 21px;
	}

	#benefits &, #fprr &, #fprr-measurement & {
		font-size: 22px;
		font-weight: 300;
	}

	#fprr &, #fprr-measurement & {
		margin-bottom: 22px;
	}

	.page-content & {
		font-size: 22px;
		margin-top: 2em;
		margin-bottom: 1em;
	}

	.litl-side & {
		font-size: 18px;
		margin-top: 24px;
		margin-bottom: 6px;
	}

	#our-program-claims & {
		font-weight: 400;
	}

	.search & {
		margin-top: 25px;
	}

	#fieldprint-projects &, #fieldprint-project-directory & {
		font-size: 13px;
		font-weight: 400;
		padding-bottom: 4px;
	}

	.project-side & {
		font-size: 18px;
		margin-bottom: 0;
	}

	#edit-form-static & {
		text-transform: uppercase;
	}

	#member-list & {
		text-align: center;
	}

	.sidebar-email & {
		font-family: $roboto;
		font-size: 24px;
		line-height: 135%;
	}

	#press-release-carousel & {
		margin-top: 0;
		margin-bottom: 4px;
	}

	#search-filter-form-2579 .sf-field-search & {
		font-size: 20px;
		font-weight: 300;
		font-family: $ssp;
		text-decoration: none;
		margin: 0;
		padding-left: 0;
	}

	#search-filter-form-2579 .sf-field-tag & {
		font-size: 20px;
		font-weight: 300;
		font-family: $ssp;
		text-decoration: none;
		margin: 0;
		padding: 25px 15px 0;
		border-top: 12px solid #d9d9d9;
	}

	#search-filter-form-2579 & {
		font-size: 16px;
		text-decoration: underline;
		font-family: $ssp;
		margin-top: 15px;
	}

	#search-filter-form-2579 .sf-field-taxonomy-ct-general-information & {
		margin-top: 16px;
	}

	#search-filter-form-2579 & {
		position: relative;
		padding-left: 1em;
	}
}

h5, .h5 {

	.timeline & {
		font-weight: 700;
		font-family: $ssp;
		font-size: 22px;
		margin-top: 0;
	}

	font-family: $ssp;
	font-weight: 300;
	font-size: 21px;

	#home-member-spotlight &, .owl-carousel & {
		line-height: 150%;
		margin: 0 0 20px;
	}

	#home-farmer-carousel & {
		font-weight: 700;
		margin-top: 0;
		font-family: $roboto;
	}

	#home-member-spotlight &, #member-carousel & {
		font-weight: 400;
		font-size: 22px;
		line-height: 130%;
		margin-top: 6px;
		font-family: $roboto;
	}

	.page-content & {
		font-size: 18px;
		margin-top: 2em;
		margin-bottom: 1em;
	}

	#fieldprint-projects &, #fieldprint-project-directory &, #search-filter-results-2579 & {
		font-size: 18px;
		margin-top: 0;
		text-decoration: underline;
		font-weight: 700;
		color: $orange2;
	}

	#search-filter-results-2579 & {
		font-weight: 400;
		font-size: 20px;
	}
}



@media (max-width: $screen-lg-min) {
	h1, .h1 {
		#home-video & {
			font-size: 44px;
		}
	}

	h2, .h2 {
		font-size: 42px;

		#home-tools-resources &, #home-program &, #home-partner-impact &, #member-rotator & {
			font-size: 40px;
		}

		#home-become-member & {
			font-size: 50px;
		}

		#home-member-spotlight & {
			margin-bottom: 35px;
		}

		.layout-wide-top-bg & {
			margin-bottom: 130px;
		}

		#join-us-intro & {
			margin-right: 10%;
	    	margin-left: 0%;
	    	font-size: 32px;
		}
	}

	h3, .h3 {
		&.gray-hash {
			margin: 40px 0 10px;
			font-size: 28px;
		}
	}

	h4, .h4 {
		.owl-carousel & {
			font-size: 26px;
			margin-top: 0;
			margin-bottom: 25px;
		}
	}

	.h5, h5 {
	    font-size: 20px;
	}
}

@media (max-width: $screen-md-min) {
	h1, .h1 {
		#home-video & {
			font-size: 40px;
		}

		#page-title & {
			font-size: 40px;
		}
	}

	h2, .h2 {

		#join-us-member-types & {
			font-size: 30px;
		}

		#our-program-benchmark &, #our-program-catalyzing &, #our-program-enabling &, #join-us-intro &, #join-us-member-types & {
			font-size: 34px;
		}

		#twitter-blk & {
			font-size: 26px;
			margin: 0;
		}
	}

	h3, .h3 {
		.single-cpt-projects .project-side {
			font-size: 18px;
		}
	}

	h5, .h5 {
		#fieldprint-projects &, #fieldprint-project-directory & {
			font-size: 16px;
		}

		#home-member-spotlight &, #member-carousel & {
			font-size: 23px;
		}
	}
}

@media (max-width: 800px) {
	h1, .h1 {
		#home-video & {
			width: 80%;
			font-size: 32px;
		}

		#page-title & {
			font-size: 36px;
			padding-left: 0;
		}
	}

	h2, .h2 {

		#our-program-benchmark &, #our-program-catalyzing &, #our-program-enabling &, #join-us-intro &, #join-us-member-types & {
			font-size: 30px;
			padding-right: 0;
		}

		#home-member-spotlight & {
			margin-bottom: 25px;
		}

		#home-become-member & {
			font-size: 42px;
		}

		#join-us-intro & {
	    	font-size: 24px;
	    	line-height: 150%;
		}

		#how-does-it-work & {
			padding-left: 100px;
		}

		#fprr &, #fprr-measurement & {
			font-size: 30px;
		}
	}

	h3, .h3 {
		font-size: 30px;

		.page-id-9 & {
			color: $black;
			font-size: 20px;
		}

		.gform_body & {
			font-size: 26px;
		}

		.page-content .member-side & {
			font-size: 20px;
		}
	}

	h4, .h4 {
		font-size: 18px;

		footer.content-info & {
			font-size: 19px;
		}

		.owl-carousel & {
			font-size: 22px;
		}

		.gov-sector & {
			font-size: 15px;
		}
	}

	h5, .h5 {
		font-size: 16px;
	}
}

@media (max-width: 740px) {
	h2, .h2 {
		#twitter-blk & {
			font-size: 34px;
		}
	}
}

@media (max-width: 600px) {
	h1, .h1 {
		#home-video & {
			width: 86%;
			font-size: 36px;
		}

		#page-title & {
			font-size: 28px;
		}
	}

	h2, .h2 {
		#our-program-benchmark &, #our-program-catalyzing &, #our-program-enabling &, #join-us-intro &, #join-us-member-types & {
			font-size: 32px;
			padding-right: 0;
		}

		#how-does-it-work & {
			padding-left: 0px;
		}

		#fprr-measurement & {
			padding-right: 0px;
		}
	}

	h3, .h3 {
		font-size: 26px;

		.page-content .member-side & {
			font-size: 26px;
		}
	}

	h4, .h4 {
		#home-program & {
			font-size: 18px;
		}
	}

	h5, .h5 {
		#home-member-spotlight &, #member-carousel & {
			font-size: 18px;
			margin-top: 0;
		}
	}
}

@media (max-width: 480px) {
	h1, .h1 {
		#home-video & {
			font-size: 28px;
			width: 90%;
			text-align: center;
		}

		#page-title & {
			font-size: 26px;
		}

		.page-template-_template-fprr #page-title & {
			font-size: 24px;
		}
	}

	h2, .h2 {
		font-size: 32px;

		.carousel & {
			margin-left: 0;
		}

		#our-program-benchmark &, #our-program-catalyzing &, #our-program-enabling &, #join-us-intro &, #join-us-member-types & {
			font-size: 24px;
		}

		#home-program &, #home-tools-resources &, #member-rotator &  {
			font-size: 32px;
		}

		#home-partner-impact & {
			font-size: 30px;
			margin-bottom: 60px;
		}

		#home-become-member & {
			font-size: 32px;
		}

		#join-us-intro & {
	    	font-size: 18px;
		}

		#fprr &, #fprr-measurement & {
			font-size: 25px;
		}
	}

	h3, .h3 {
		&.gray-hash {
			font-size: 24px;
		}
	}

	h4, .h4 {
		font-size: 20px;

		#home-program & {
			margin-top: 12px;
			text-align: center;
			font-size: 14px;
		}

		footer.content-info & {
			font-size: 17px;
		}
	}
}

@media (max-width: 320px) {
	h2, .h2 {
		#home-member-spotlight & {
			margin-bottom: 0;
		}
	}

	h4, .h4 {

		#home-program & {
			font-size: 13px;
		}

		footer.content-info & {
			font-size: 14px;
		}
	}
}

@media (min-width: 1690px) {
	h2 {
		#fprr-measurement & {
			padding-right: 23%;
		}
	}
}

@media (min-width: 1700px) {
	h2 {
		#fprr-measurement & {
			padding-right: 39%;
		}
	}
}

/*********************
COMMON ELEMENTS
*********************/

.page-content .content-col > * :first-child, .page-content .content-col > h3:first-child, .page-content .content-col > h2:first-child {
	margin-top: 0;
}

.post-list {
	display: block;
	list-style: none;
	padding-left: 0;
	margin: 40px 0 0;

	li {
		display: block;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			display: block;
		}
	}

	time {
		font-weight: 700;
	}
}

@media (max-width: 800px) {
	.post-list {
		margin: 30px 0 20px;
		li {
	    	margin-bottom: 15px;
		}
	}
}

ul.pagination {
	text-align: center;
	padding-left: 0;
	display: block;

	> li {
		display: inline-block;
		margin: 0;
		padding: 0;

		a {
			color: $orange2;
			font-weight: 700;
			padding: 6px 12px !important;
		}

		&.active {

			> a {
				background: none;
				border: 0;
				color: $black;
				font-weight: 300;

				&:hover {
					background: none;
				}
			}
		}
	}
}