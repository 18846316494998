// Search form
/* .search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}
*/

.cse input.gsc-input, input.gsc-input {
background-image: none !important;
background-repeat:no-repeat;
background-color: $black;
}


/* SIDEBAR SIGNUP */

body.single-post .litl-side {
	#gform_wrapper_1 {
		margin-top: 0 !important;
	}

	.gform_footer {
		padding: 0 !important;
		margin: 0 !important;
	}

	.gform_wrapper {
		.top_label li.gfield.gf_inline {
			width: 100% !important;
		}

		input {
			border: none;
			height: 40px;
			padding: 0 10px;
			background: #f5f5f5;
			margin-top: 0;

			&::-webkit-input-placeholder {
			   font-style: italic;
			}

			&:-moz-placeholder { /* Firefox 18- */
			   font-style: italic;
			}

			&::-moz-placeholder {  /* Firefox 19+ */
			   font-style: italic;
			}

			&:-ms-input-placeholder {
			   font-style: italic;
			}

			@include transition(all 0.15s ease);

			&:focus {
				background: $white;
				outline: none;
				border: none;
				@include transition(all 0.15s ease);
			}
		}

		button {
			margin-top: 8px;
			float: right;
			color: $white;
			border: none;
			background: $orange2;
			padding: 5px 14px;
			font-size: 18px;
			font-weight: 900;
			@include transition(all 0.15s ease);

			&:hover {
				background: $orange3;
				@include transition(all 0.25s ease);
			}
		}
	}
}
/* FOOTER NEWSLETTER SIGNUP */

.footer-newsletter #gform_1 {

	input {
		border: 4px solid #808080;
		border-right: none;
		display: inline;
		color: #808080;
		margin-top: 0;
		font-size: 18px;
		padding-left: 10px;
		padding-right: 10px;
		background: #e5e5e5;
		height: 52px;

		@include transition(all 0.15s ease);

		&:focus {
			background: $white;
			@include transition(all 0.15s ease);
		}

		&::-webkit-input-placeholder {
		   font-style: italic;
		}

		&:-moz-placeholder { /* Firefox 18- */
		   font-style: italic;
		}

		&::-moz-placeholder {  /* Firefox 19+ */
		   font-style: italic;
		}

		&:-ms-input-placeholder {
		   font-style: italic;
		}
	}

	button {
		vertical-align: bottom;
		border: 4px solid #808080;
		border-left: none;
		background: $orange2;
		padding: 5px 14px;
		font-size: 24px;
		font-weight: 900;
		height: 52px;
		@include transition(all 0.15s ease);

		&:hover {
			background: $orange3;
			@include transition(all 0.25s ease);
		}
	}

	#field_1_1 {
		padding-right: 0;
		vertical-align: top;
	}

	.ginput_container_email {
		margin-top: 0;
	}

	div.ginput_container {
		width: 250px;
	}
}

.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield input[type=radio] {
    margin-left: 1px;
    margin-bottom: 1px;
}

body #gform_wrapper_6 {

	ul.gform_fields > li.gfield {
		margin-left: 20px !important;
		margin-right: 20px !important;

		&.gfield_html {
			margin-top: 45px;

			p {
				margin: 10px 0 15px;
			}
		}

		&.gf_left_half, &.gf_right_half {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		&.gf_left_half {
			padding-left: 20px;
		}

		&.gf_right_half {
			padding-right: 20px;
		}

		&.gsection {
			margin-left: 0 !important;
			margin-right: 15px !important;
		}
	}

	.gform_footer {
		text-align: right;
	}

	#field_6_16 {
		font-weight: 400 !important;
	}
}

body .gform_wrapper {
	margin-top: 30px !important;

	h2.gsection_title {
		font-size: 24px;
		margin-bottom: 8px;
	}

	.gform_body {
    	width: auto;
    	text-align: center !important;
	}

	.gfield_description, .gsection_description {
    	font-style: italic !important;
    	font-size: 16px;
    	line-height: 125%;
    	color: #6d6d6d;
	}

	.gsection_description {
		margin-top: 7px;
	}

	.top_label .gfield_label {
		font-size: 16px !important;
		margin-bottom: 5px;
	}

	input, textarea {
		border: #d2d2d2 solid 1px;
		padding: 0 10px !important;
		font-size: 15px !important;
	}

	textarea.medium {
	    font-size: 15px;
	    padding-top: 6px;
	}

	ul.gform_fields {
		text-align: center;

		&#gform_fields_4, &#gform_fields_6, &#gform_fields_9, &#gform_fields_10 {
			text-align: left;
		}

		li.gsection {
			margin-top: 45px;
			background: #ddd;
			padding: 25px 20px 15px;
			border-bottom: $black dashed 1px;
		}
	}

	ul.gfield_radio li label {
	    padding: 4px 0 0 10px;
	    font-weight: 400;
	}

	form {
		text-align: center !important;
		background: #ebebeb;
		padding: 22px;

		&#gform_1 {
			background: transparent;
			padding: 0;
		}

		&#gform_3 {
			background: transparent;
			padding: 0;
		}
	}
}

@media (max-width: $screen-lg-min) {
	.footer-newsletter #gform_1 div.ginput_container {
	    width: 150px;
	}
}

@media (max-width: 768px) {
	.footer-newsletter #gform_1 div.ginput_container {
	    width: 150px;
	}
}

@media (max-width: 480px) {
	.footer-newsletter #gform_wrapper_1.gform_wrapper {
		margin-top: 10px !important;
	}
}

#gform_wrapper_2.gform_wrapper {
	margin-top: 0 !important;

	li.gfield:first-child {
		margin-top: 0;
	}
}

.gform_wrapper {
	input, textarea {
		border: none;

		&:focus {
			outline: 1px solid #aaa;
		}
	}

	h5 {
		font-weight: 700;
		margin-top: 1em;
		margin-bottom: 0px;
	}
}

.gform_wrapper .gform_footer {

	#join-us & {
		text-align: right;
	}
}

.gform_confirmation_message_1 {
	color: $green3;
	font-weight: 700;
}

.gform_wrapper #gform_fields_2 {
	text-align: left;
	padding-left: 20px;

	input {
		height: 50px;
		padding-left: 15px;
	}

	input, textarea {
		width: 100%;
		border: 4px solid #ff9337;
		color: #898989;
		font-size: 16px;
		font-family: $ssp;
		font-weight: 400;
		background: #f5f5f5;

		&:focus {
			background: $white;
			@include transition(all 0.15s ease);
		}

		&::-webkit-input-placeholder {
		   color: #898989;
		}

		&:-moz-placeholder { /* Firefox 18- */
		   color: #898989;
		}

		&::-moz-placeholder {  /* Firefox 19+ */
		   color: #898989;
		}

		&:-ms-input-placeholder {
		   color: #898989;
		}
	}

	.gform_wrapper div.validation_error {
		background: #ff0000;
	    color: #ffffff;
	    font-size: 14px;
	    border-top: 2px solid #ffffff;
	    border-bottom: 2px solid #ffffff;

	}
}

.gform_wrapper #gform_fields_3 {
	text-align: left;

	li.gfield {
		margin-top: 3px !important;
		margin-bottom: 3px !important;
	}

	.top_label div.ginput_container {
		margin-top: 4px !important;
	}

	#field_3_5 .ginput_container {
		margin-top: 20px !important;
	}

	select {
		@include rounded(0);
		border: 4px solid #bdbdbd;
		width: 100%;
	}

	input, textarea {
		border: 4px solid #bdbdbd;
		color: #898989;
		font-size: 16px;
		font-family: $ssp;
		font-weight: 400;
		padding-left: 20px;
		font-family: $ssp;
		font-size: 14px;
		width: 100%;
	}

	&:focus {
		background: $white;
		@include transition(all 0.15s ease);
	}

	&::-webkit-input-placeholder {
	   color: #898989 !important;
	}

	&:-moz-placeholder { /* Firefox 18- */
	   color: #898989 !important;
	}

	&::-moz-placeholder {  /* Firefox 19+ */
	   color: #898989 !important;
	}

	&:-ms-input-placeholder {
	   color: #898989 !important;
	}
}

#gform_2 {
	background: none;

	.gform_footer input[type="submit"] {
		border: 4px solid $orange2 !important;
		text-align: center;
		color: $orange2 !important;
		font-family: $roboto !important;
		background: $white !important;
		padding: 5px 24px !important;
		font-size: 24px !important;
		font-weight: 900;
		height: 52px !important;
		@include transition(all 0.15s ease);
		margin-right: 16px !important;

		&:hover {
			color: $orange3 !important;
			border-color: $orange3 !important;
			@include transition(all 0.25s ease);
		}
	}
}

#gform_3 .gform_footer {
	text-align: right !important;

	input {
		margin-right: 0 !important;
	}
}

.gform_footer input[type="submit"] {
    border: 0 !important;
    color: #ffffff !important;
    background: $orange2 !important;
    padding: 3px 24px;
    height: 42px;
    font-family: $roboto;
    font-weight: 700;
    @include transition(all 0.15s ease);

    &:hover {
    	@include transition(all 0.15s ease);
    	background: $orange3 !important;
    }
}

/* Speaker Request Form */

.gform_wrapper.gf_browser_gecko .gfield_time_ampm select {
    max-width: auto !important;
    width: 100px !important;
    padding-right: 5px !important;
}

#gform_wrapper_4 .gform_footer {
	text-align: right;
}

#field_4_26 h3 {
	margin-top: 0;
}

.gform_wrapper .gform_footer img.gform_ajax_spinner {
    display: none !important;
    max-width: 30px !important;
    width: 1px !important;
    height: 1px !important;
}

.gform_confirmation_message {
	font-weight: 700;
}

#gform_confirmation_message_2 {
	color: $white !important;
}

body #gform_wrapper_9 {

	ul.gform_fields > li.gfield {
		margin-left: 20px !important;
		margin-right: 20px !important;
	}

	.top_label li.gfield.gf_inline.gf_repeater_child_field {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	.top_label li {
		&#field_9_22-1-1, &#field_9_22-1-2, &#field_9_22-1-3, &#field_9_22-1-4, &#field_9_22-1-5, &#field_9_22-1-6, &#field_9_22-1-7, &#field_9_22-1-8, &#field_9_22-1-9, &#field_9_22-1-10 {
			margin-left: 20px !important;
		}
	}
}

body .gform_wrapper .top_label li.gfield.gf_inline.gf_repeater_child_field {
	max-width: 16%;
	margin-top: 15px;

	.gfield_label {
    	font-size: 12px !important;
	}
}

body .gform_wrapper ul.gform_fields li.gfield {

	h2.gf_repeater_title {
		font-size: 22px;
		margin-top: 1em;
		margin-bottom: .75em;
		font-family: $ssp;
	}

	div.ginput_container.ginput_container_repeater-end {
	    margin-bottom: 40px;
	}
}

body #gform_wrapper_10 {
	ul.gform_fields li.gfield#field_10_5 {
		margin-bottom: 40px;
	}
}

/* PROFILE BUILDER */

#wppb_form_success_message {
	font-weight: 700;
	color: $green3;
	font-size: 24px;
	padding-left: 0;
}

.wppb-user-forms {
	background: #ebebeb;
	padding: 0 0 25px 25px;

	.page-template-content-member-login &, &#wppb-recover-password {
		background: none;
	}

	h4 {
		margin-bottom: 0;
		margin-top: 1.25em;
		text-transform: uppercase;
	}

	ul {
		list-style: none;
		margin: 20px 0;
		padding-left: 0;

		.layout & {
			padding-left: 0;
		}
	}

	li {
		list-style: none;
	}

	input, textarea {
		padding: 2px 10px;
		border: #999 solid 2px;
		box-shadow: none !important;
	}

	input[type="submit"] {
		font-family: $roboto;
		width: auto;
		background: $orange2;
		color: $white;
		font-weight: 700;
		float: right;
		border: 0;
		box-shadow: none;
		padding: 6px 36px;
		@include transition(all 0.15s ease);

		&:hover, &:focus {
			background: $orange3;
			@include transition(all 0.15s ease);
		}
	}

	.wppb-form-field.pbpl-class select {
		padding: 5px 0 7px 10px;
	}

	.login-remember {
		float: left;
		width: 150px;
		margin-top: 5px;

		input {
			width: auto;
			margin-right: 3px;
		}
	}

	.login-register-lost-password {
		display: block;
		clear: both;
	}

	#pass-strength-result {
		font-weight: 400;
		font-size: 14px;
		font-style: italic;
		font-family: $roboto;
		display: block;
		clear: left;
		margin-right: 5px;
	}

	&#wppb-register-user, &#wppb-register-user-register-form {
		margin-top: 45px;

		.wppb-description-delimiter {
		    margin-left: auto;
		    clear: left;
		    display: block;
		}

		input[type=submit] {
			float: none;
		}

		#send_credentials_via_email {
			margin-right: 10px;
		}

		li {
			display: inline-block;
			width: 45%;
			float: left;
			margin-right: 5%;
			margin-bottom: 25px;

			&.wppb-heading {
				width: 100%;
			}

			&.wppb-default-display-name-publicly-as {
				display: none;
			}

			&#wppb-form-element-14 label {
				width: auto;
				margin-right: 15px;
			}

			&#wppb-form-element-8, &#wppb-form-element-21, &#wppb-form-element-23, &#wppb-form-element-25, &#wppb-form-element-32, &#wppb-form-element-34, &#wppb-form-element-12 {
				clear: left;
			}

			&#wppb-form-element-34, &#wppb-form-element-35, &#wppb-form-element-36 {
				width: 96%;
			}

			&#wppb-form-element-32, &#wppb-form-element-33 {
				margin-top: 15px;
				width: 100%;
				clear: left;
				margin-bottom: 0;
			}

			&#wppb-form-element-33 {
				margin-top: 0px;
			}

			&#wppb-form-element-39, &#wppb-form-element-40 {
				margin-top: -15px;
				margin-bottom: 25px;
			}

			&#wppb-form-element-40, &#wppb-form-element-41, &#wppb-form-element-42  {
				margin-bottom: 35px;
			}

			ul.wppb-checkboxes {
				margin-top: 10px;
			}

			li {
				float: none;
				width: 100%;
				margin-bottom: 8px;

				label {
					width: auto;
					display: inline;
					font-weight: 400;
					margin-left: 10px;
				}
			}
		}
	}
	&#wppb-edit-user-staff-edit-form {
		li#wppb-form-element-8 {
			clear: none;
		}
		li#wppb-form-element-43 {
			clear: left;
		}
	}

	&#wppb-edit-user-member-edit-form, &#wppb-edit-user-staff-edit-form {
		margin-top: 45px;
		margin-bottom: 0;
		position: relative;

		input[type=submit] {
			float: none;
			width: auto;
		}

		input, textarea {
			width: 100%;
		}

		select {
			min-width: 240px;
			max-width: 100%;
		}

		label {
			display: block;
			font-size: 14px;
			line-height: 100%;
			margin-bottom: 6px;
		}

		li {
			display: inline-block;
			width: 45%;
			margin-right: 5%;
			margin-bottom: 20px;
			vertical-align: top;

			&#wppb-form-element-1, &#wppb-form-element-26, &#wppb-form-element-37 {
				width: 100%;
			}

			&#wppb-form-element-3 {
				clear: left;
			}
		}

		.wppb-description-delimiter {
			font-size: 13px;
			font-style: italic;
		}

		.form-submit {
			position: absolute;
			right: 25px;
			bottom: 0;
			z-index: 12;
		}
	}
}

#edit-form-static {
	background: #ebebeb;
	padding: 10px 25px 25px;
	margin-top: -50px;
	position: relative;
	z-index: 11;
	font-size: 16px;

	p {
		line-height: 130%;
	}

	a {
		color: $black;
		text-decoration: underline;
		font-weight: normal;

		&.email {
			color: $orange2;
			font-weight: 700;
		}
	}
}

#select_user_to_edit_form {
	display: none !important;
}


@media screen and (max-width: 660px) {
	.wppb-user-forms {

		&#wppb-register-user, &#wppb-edit-user-member-edit-form, &#wppb-edit-user-staff-edit-form {

			li {
				display: block;
				width: calc(100% - 25px);
				margin-bottom: 20px;
			}
		}
	}
}

.wppb-error, .wppb-form-error, .wppb-warning, #wppb_general_top_error_message {
	color: #ff0000;
	margin: 8px 0;
}

/* LOGIN FORM */

#wppb-login-wrap {

	input {

		&[type="submit"] {
			width: auto;
		}
	}
}

a.forgot-password {
	display: inline-block;
	margin-top: 20px;
	float: right;
}

/* RECOVER PASSWORD */

#wppb-recover-password-container {
	max-width: 450px;
	margin: 0 auto;
	ul {
		list-style: none;
		padding-left: 0;
	}
}

.wppb-user-forms .login-remember input {
	margin-left: 1.5px;
    margin-right: 3px;
}


/* COMMENT FORMS */

.comment-form textarea {
	color :$black;
}

.comment-form input[type=submit] {
	@include rounded(0);
	background: $orange2;
	border: none;
	color: $white;
	@include transition(all 0.15s ease);
	float: right;

	&:hover {
		background: $orange3;
		@include transition(all 0.15s ease);
	}
}

p.form-submit {
	display: block;
}

#comments {

	h2 {
		color: #356d7f;
		font-family: $ssp;
		font-weight: 300;
		font-size: 24px;
		border-bottom: 1px solid #b0b0b0;
		padding-bottom: 3px;
	}

	&:after {
		display: block;
		content: '';
		clear: both;
		margin-bottom: 25px;
	}
	.logged-in-as {
		display: none;
	}
}

.comment {
	padding: 25px;
}

.comment.even {
    background: #f8f9fa;
}

.comment:not(.depth-1) {
    margin-top: 0;
    padding: 15px;
}

.comment.odd {
    background-color: #e6e6e6;
}

	.comment-metadata {
		float: right;
	}

	.comment-author {
		color: #356d7f;
		font-family: $ssp;
		font-weight: 400;
		font-size: 18px !important;
	}

	.comment-content, .comment-meta {
		font-size: 15px;

		p {
			margin: 15px 0 !important;

			&:last-child {
				margin-bottom: 6px !important;
			}
		}
	}

	.reply {
		height: 22px;
		margin-bottom: 40px;

		a {
		float: right;
		font-weight: 400;
		font-size: 15px !important;
		}
	}
