
/*********************
TRANSITION
*********************/

@mixin transition($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes:      ("-webkit-", "" );
  @each $prefix in $prefixes {
    #{$prefix}transition: $transition;
  }
}

/*********************
CSS3 GRADIENTS
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */

@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}

/*********************
BOX SIZING
*********************/

/* @include box-sizing(border-box); */

@mixin box-sizing($type: border-box) {
	-webkit-box-sizing: $type;
	-moz-box-sizing:    $type;
	-ms-box-sizing:     $type;
	box-sizing:         $type;
}

/*********************
BORDER RADIUS
*********************/

/* USAGE: @include rounded(4px); */

@mixin rounded($radius: 4px) {
	-webkit-border-radius: $radius;
	-moz-border-radius:    $radius;
	border-radius:         $radius;
}

/* USAGE: @include border-radius(4px,4px,0,0); */

@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	-webkit-border-top-right-radius:    $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius:  $bottomleft;
	-webkit-border-top-left-radius:     $topleft;
	-moz-border-radius-topright:        $topright;
	-moz-border-radius-bottomright:     $bottomright;
	-moz-border-radius-bottomleft:      $bottomleft;
	-moz-border-radius-topleft:         $topleft;
	border-top-right-radius:            $topright;
	border-bottom-right-radius:         $bottomright;
	border-bottom-left-radius:          $bottomleft;
	border-top-left-radius:             $topleft;
	-webkit-background-clip:            padding-box;
	-moz-background-clip:               padding;
	background-clip:                    padding-box;
}

/*********************
OPACITY
*********************/

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

/*********************
CIRCLE
*********************/

@mixin circle($width) {
  width: $width;
  height: $width;
  vertical-align: middle;
  text-align: center;
  line-height: $width - ($width/6);
  -webkit-border-radius: $width/2;
     -moz-border-radius: $width/2;
          border-radius: $width/2;
}
