
/* LEAVE THIS HERE FOR NEXA RUST SANS-BLACK TO WORK !!! */
@import url("//hello.myfonts.net/count/320686");

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$black:					#151413;
$white:					#ffffff;

$gray:					#dddee0;
$medgray:				#4d4d4d;
$darkgray:			#333333;
$bordergray:		#7d837d;

$blue1:					#94c1cf;
$blue2:					#69a9bc;

$cream1:				#ebe4d3;
$cream2:				#e1d7bd;

$green0:        #dbe2b7;
$green1:				#c0ce88;
$green2:				#aabe62;
$green3:				#93af67;
$green4:				#839a60;
$green5:				#728661;

$orange1:				#ffa237;
$orange2:				#ff8937;
$orange3:				#f06934;

$red1:					#e47b6b;
$red2:					#db6c61;
$red3:					#d15c57;

$teal1:         #6da3ae;



/*********************
TYPOGRAPHY
*********************/

/* FONTELLO ICON FONTS */

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?35789860');
  src: url('../fonts/fontello.eot?35789860#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?35789860') format('woff2'),
       url('../fonts/fontello.woff?35789860') format('woff'),
       url('../fonts/fontello.ttf?35789860') format('truetype'),
       url('../fonts/fontello.svg?35789860#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  margin-right: .2em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-plus:before { content: '\e800'; } /* '' */
.icon-left-open-big:before { content: '\e801'; } /* '' */
.icon-right-open-big:before { content: '\e802'; } /* '' */
.icon-user-outline:before { content: '\e803'; } /* '' */
.icon-twitter:before { content: '\e804'; } /* '' */
.icon-phone:before { content: '\e805'; } /* '' */
.icon-mail:before { content: '\e806'; } /* '' */
.icon-play:before { content: '\e807'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-search:before { content: '\f50d'; } /* '' */

/* NEXA RUST SANS BLACK */
@font-face {
	font-family: 'NexaRustSans-Black';
	src: url('../fonts/320686_0_0.eot');
	src: url('../fonts/320686_0_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/320686_0_0.woff2') format('woff2'),
		url('../fonts/320686_0_0.woff') format('woff'),
		url('webfonts/320686_0_0.ttf') format('truetype');
}

$nexa:			'NexaRustSans-Black', sans-serif;
$ssp:			'Source Sans Pro', sans-serif;
$roboto:	'Roboto Slab', serif;
