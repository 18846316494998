
/* PAGE : HOME */

@media (max-width: 600px) {
    .fullscreen-bg {
        background: url('../images/f2m-intro@2x.jpg') 20% center / cover no-repeat;
    }
}

/* home :: our vision */

#home-vision {
	h2:after {
		background: url('../images/bg-gray-hashes@2x.png') repeat-x;
		background-size: auto 8px;
	}
}

/* home :: our members */

#home-members {
	background: url('../images/bg-home-our-members@2x.jpg') left top no-repeat;
	background-size: cover;
}

@media (max-width: $screen-lg-min) {
	#home-members {
	    background: url(../images/bg-home-our-members@2x.jpg) 0% 0 no-repeat;
	    background-size: cover;
	}
}

@media (max-width: 767px) {
	#home-members {
		background-size: 1200px auto;
		background-position: -130px;
	}
}

/* home :: member spotlight */

#home-member-spotlight {
	h2:after {
		background: url('../images/bg-black-hashes@2x.png') repeat-x;
		background-size: auto 8px;
	}
}

/* home :: tools & resources */

#home-tools-resources {
	background: $white url('../images/bg-home-tools-resources@2x.jpg') center 50px no-repeat;
	background-size: 100% auto;
	padding-top: 175px;

	.col-md-3 {

		span {
			&.icon-fieldprint {
				background: url('../images/icon-home-fieldprint@2x.png') no-repeat;
				background-size: contain;
			}

			&.icon-national-indicator {
				background: url('../images/icon-home-national-indicators@2x.png') no-repeat;
				background-size: contain;
			}
		}
	}
}

@media (max-width: 1695px) {
	#home-tools-resources {
		background: $white url('../images/bg-home-tools-resources@2x.jpg') center 50px no-repeat;
		background-size: 1700px 250px;
	}
}

/* home :: farmer's spotlight */

#home-farmer-spotlight {
	background: url('../images/bg-home-farmer-spotlight@2x.jpg') center top no-repeat;
	background-size: cover;
	background-position: 0 0;

	h2:after {
		background: url('../images/bg-white-hashes@2x.png') repeat-x;
		background-size: auto 8px;
	}
}

/* home :: partnering for impact */

#home-partner-impact {
	background: $white url('../images/bg-home-partner-impact-head@2x.jpg') 0 65px no-repeat;
	background-size: 100% auto;
	padding: 130px 0;

	.main-wrap {
		background: $white url('../images/bg-home-partner-impact@2x.jpg') no-repeat;
		background-size: auto 100%;
	}

	.col-md-4 {
		position: relative;

		span {

			&.icon-partnering-impact {
				background: url('../images/icon-home-partnering-impact@2x.png') no-repeat;
				background-size: contain;
			}
		}
	}
}

@media (max-width: 1695px) {
	#home-partner-impact {
		background: $white url('../images/bg-home-partner-impact-head@2x.jpg') center 65px no-repeat;
		background-size: 1700px 196px;
	}
}

@media (max-width: $screen-lg-min) {
	#home-partner-impact {
		.main-wrap {
			background: $white url('../images/bg-home-partner-impact@2x.jpg') no-repeat;
			background-size: auto 80%;
		}
	}
}

@media (max-width: 800px) {
	#home-partner-impact {
		.main-wrap {
			background-size: auto 70%;
			padding-top: 60px;
		}
	}
}

/* home :: join us */

#join-us, #home-become-member {
	background: url('../images/bg-home-join-us@2x.jpg') no-repeat;
	background-size: cover;
}

/* alliance */

#alliance-long-left {
	background-image: url('../images/bg-alliance-our-vision-head@2x.jpg');
}

.page-id-5, .page-id-716 {
	.layout-two-col-text {
		&:before {
			background: url('../images/bg-teal-sponge-border@2x.jpg') center top no-repeat;
			background-size: 100% 104px;
		}
	}

	.bg-wrap {
		background: url(../images/bg-alliance-crops-vert@2x.jpg) no-repeat;
		background-size: auto 1600px;
		background-position: -127px 400px;
	}
}


@media (max-width: $screen-lg-min) {
	/* ALLIANCE */
	.page-id-5, .page-id-716 {
		.bg-wrap {
			background-size: 338px auto;
			background-position: -166px 321px;
		}
	}
}

@media (max-width: 480px) {

	/* ALLIANCE */
	.page-id-5 .bg-wrap, .page-id-716 .bg-wrap {
	    background-size: 270px auto;
	}
}

/* OUR MEMBERS */

#member-rotator {
	&.bg-teal:before {
		background: url('../images/bg-teal-sponge-border@2x.jpg') no-repeat center top;
		background-size: 100% 104px;
	}
}

/* OUR PROGRAMS */

#our-program-benchmark {
	background: $white url('../images/bg-wrap-width-left-lean@2x.jpg') center 50px no-repeat;
	background-size: 1700px 250px;
}

#our-program-catalyzing {
	background: $white url('../images/bg-island-btm-right@2x.jpg') center 50px no-repeat;
	background-size: 1700px 250px;
}

#our-program-enabling {
	background: $white url('../images/bg-island-top-right@2x.jpg') center 50px no-repeat;
	background-size: 1700px 250px;
}

#member-rotator.bg-green-sponge {
	background: url('../images/bg-green-sponge@2x.jpg') center top no-repeat;
	background-size: cover;
}

/* JOIN US */

#join-us-intro {
	background: $white url('../images/bg-island-btm-right@2x.jpg') center 50px no-repeat;
	background-size: 1700px 250px;
}

#join-us-member-types {
	background: $white url('../images/bg-island-top-right@2x.jpg') center 50px no-repeat;
	background-size: 1700px auto;
}

/* FIELDPRINT PLATFORM */

#benefits, #fprr, #fprr-measurement  {
	background: $white url('../images/bg-island-top-right@2x.jpg') center 0px no-repeat;
	background-size: 1700px auto;
}

#how-does-it-work {
	background: $white url('../images/bg-island-top-left@2x.jpg') center 0px no-repeat;
	background-size: 1700px auto;
}

#fieldprint-cta {
	padding: 115px 0;
	background: url('../images/bg-green-sponge-top@2x.jpg') center top no-repeat;
	background-size: cover;
}

/* NEWS + PRESS */

#news-press {
	background: $white url('../images/bg-island-even@2x.jpg') center 50px no-repeat;
	background-size: 1700px auto;
}

#news-press {
	background: $white url('../images/bg-island-oblong@2x.jpg') center 50px no-repeat;
	background-size: 1700px auto;
}

#twitter-blk {
	display: block;
	background: url('../images/bg-twitter@2x.jpg') no-repeat;
	background-size: 100% auto;
}

@media (max-width: 740px) {
	#twitter-blk {
		background: none;
		background: #00b7f3;
		@include rounded(10);
		min-height: 1px;
	}
}

/* FIELDPRINT PROJECTS DIRECTORY */

#case-study-rotator {
	&.bg-blue-slate:before {
		background: url('../images/bg-blue-gray-sponge-border@2x.jpg') no-repeat center top;
		background-size: 100% 95px;
	}
}

