/*********************
FOOTER
*********************/

#home-feeds {
	.col {
		padding-right: 40px;
	}
}

@media (max-width: $screen-md-min) {
	#home-feeds {
		.col {
			&:last-child {
				margin-top: 50px;
			}
		}
	}
}

footer.content-info {
	background: $medgray;
	color: $white;
	border-top: 6px solid $white;
	padding: 80px 0 0px;

	> .container {
		padding-bottom: 50px;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline;

			&.sep {
				margin: 0 5px;
			}

			&:last-child.sep {
				display: none;
			}
		}
	}

	a {
		color: $white;
		font-weight: 300;
	}
}


.footer-social, .footer-newsletter {
	text-align: center;
}

.footer {
	address {
		font-size: 24px;
	}
}

#footer-social {
  margin: 30px 0 0;
  display: block;

  li {
    display: inline;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }

    a {
		display: inline-block;
		color: $white;
		width: 64px;
		background: $black;
		height: 64px;
		border-radius: 100%;
		text-align: center;
		vertical-align: bottom;
		line-height: 66px;
		font-size: 34px;
		@include transition(all 0.15s ease);

		&.icon-twitter {
			background: #618bac;
		}

		&.icon-linkedin {
			background: #3e7c9a;
		}

		&.icon-youtube-play {
			background: #c65c57;
		}

		&:hover {
			transform: scale(1.1);
			@include transition(all 0.15s ease);
			text-decoration: none;
		}
    }
  }
}

#footer-copyright {
	display: block;
	min-height: 44px;
	line-height: 44px;
	background: $darkgray;
	color: $bordergray;
	font-size: 14px;
	font-family: $ssp;
	font-weight: 900;

	a {
		color: $bordergray;
		font-weight: 900;
	}
}

@media (max-width: $screen-lg-min) {
	#footer-social li {
		margin-right: 12px;

		a {
		    width: 39px;
		    height: 39px;
		    line-height: 40px;
		    font-size: 22px;
		}
	}

	footer.content-info {
		padding-top: 50px;

		address {
			font-size: 14px;
		}

		> .container {
		    padding-bottom: 10px;
		}
	}
}

@media (max-width: 767px) {
	.footer {
		.col-xs-7 {
			padding-left: 0;
		}
		.col-xs-5 {
			padding-left: 0;
			padding-right: 0;
		}

		.gform_wrapper li.gf_inline div.ginput_container {
		    white-space: nowrap !important;
		}

		#gform_1 div.ginput_container {
			display: inline-block;
		}
	}

	#footer-copyright {
		.col-xs-12 {
			font-size: 14px;
		}
	}
}

@media (max-width: 480px) {
	#footer-social {
		margin-top: 18px;

		li {
			margin-right: 10px;

			a {
			    width: 32px;
			    height: 32px;
			    line-height: 32px;
			    font-size: 19px;
			}
		}
	}

	.footer-newsletter.col-xs-12 {
		padding-left: 0;
		padding-right: 0;
	}

	#footer-copyright {
		line-height: 200%;
		padding: 15px 0;
		.col-xs-12 {
			text-align: center;
			font-size: 12px;
		}
	}
}

@media (max-width: 320px) {
	#footer-social {
		margin-top: 15px;

		li {
			margin-right: 8px;

			a {
			    width: 28px;
			    height: 28px;
			    line-height: 28px;
			    font-size: 16px;
			}
		}
	}
}